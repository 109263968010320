/**
 * Get LCID (locale id) by locale code.
 *
 * @param {String} localeCode - Locale code, format: xx_XX.
 * @returns {String} - 2 digit locale id.
 */
export function getLcid(localeCode) {
  const mapping = {
    en_GB: 'en-gb',
    nl_BE: 'nl-be',
  };

  // Return mapping if it exists
  if (mapping.hasOwnProperty(localeCode)) {
    return mapping[localeCode];
  }

  // Otherwise just modify the locale code
  // eslint-disable-next-line no-magic-numbers
  return localeCode.substr(0, 2);
}
