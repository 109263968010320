<template>
  <transition
    :name="transition"
    appear>
    <component
      :is="tag"
      class="loader loader__wrapper"
      v-bind="$attrs"
      :class="{
        'loader--bg': type === 'full-screen',
        [`loader--${type}`]: !!type,
      }">
      <b-spinner
        class="m-auto"
        :variant="variant"
        :small="small" />
    </component>
  </transition>
</template>

<script>
import { hasVariantProp } from '@/mixins/hasVariantProp';

export default {
  name: 'Loader',
  mixins: [hasVariantProp],
  props: {
    tag: {
      default: 'div',
      type: String,
    },
    type: {
      default: null,
      type: String,
    },
    transition: {
      default: 'fade',
      type: String,
    },
    small: Boolean,
  },
};
</script>
