import { i18n } from '@/services/i18n';

/**
 * Always add the lang parameter from the set locale, make it persist across navigation and remove the cc param.
 *
 * @param {Route} to
 * @param {Route} from
 *
 * @returns {Route|null}
 */
export function getRouteWithLangParameter(to, from) {
  if (!to.query.lang && (from.query.lang || i18n.locale)) {
    delete to.query.cc;
    to.query.lang = from.query.lang || i18n.locale;

    return to;
  }

  return null;
}
