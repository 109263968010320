import '@/services/directives';
import '@/services/filters';
import '@/services/gtm';
import '@/services/properties';
import '@/services/vuelidate';
import '@/services/components';
import '@/services/dayjs';
import { env, isDev } from '@/helpers/environment';
import { environment, platform, subdomain } from '@/helpers/platform';
import App from '@/App';
import Vue from 'vue';
import { createI18n } from '@/services/i18n';
import { createRouter } from '@/router';
import { createStore } from '@/store';
import log from '@/helpers/log';

Vue.config.devtools = isDev;
Vue.config.productionTip = false;

log.info(
  `NODE_ENV: ${env}`
  + `\nEnvironment: ${environment || 'prod'}`
  + `\nPlatform: ${platform}`
  + `\nSubdomain: ${subdomain || '-'}`,
);

new Vue({
  el: '#app',
  name: 'ConsumerPortal',
  // Store must be created before the router!
  store: createStore(),
  i18n: createI18n(),
  router: createRouter(),
  render: (h) => h(App),
});
