import { BPOST, DHL_FOR_YOU, DPD } from '@/helpers/carriers';
import { ApiError } from '@/services/ApiError';
import MyParcelApi from '@/services/MyParcelApi';
import { STORE_SOCIAL_SHIPPING_LINK } from '@/store/modules/social-shipping/constants';
import { carrierMapping } from '@/helpers/carrierMapping';
import { isDemo } from '@/services/functions/isDemo';
import merge from 'lodash-es/merge';

/**
 * Update a social shipping link.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} context.state - Vuex state.
 * @param {Object} shipmentOptions - Social shipping link data.
 *
 * @returns {Promise<ApiError|Object>}
 */
export async function putSocialShippingLink({ commit, state }, shipmentOptions) {
  if (isDemo()) {
    return;
  }

  const data = {
    shop_id: state.socialShippingLink.shop_id,
    shipment_options: merge({}, state.socialShippingLink.shipment_options, shipmentOptions),
  };

  const {carrier} = data.shipment_options;
  const {name: shipmentCarrierName} = carrierMapping.find(({id}) => id === carrier);

  // DPD needs to have drop off at postal point property set as 0.
  if (shipmentCarrierName === DPD) {
    data.shipment_options.options.drop_off_at_postal_point = 0;
  }

  // Some carriers cannot have a delivery date property when using the shipping link.
  if ([DPD, BPOST, DHL_FOR_YOU].includes(shipmentCarrierName)) {
    delete data.shipment_options.options.delivery_date;
  }

  let response;
  try {
    response = await MyParcelApi.putSocialShippingLink(data);
  } catch (e) {
    return new ApiError(e);
  }

  commit(STORE_SOCIAL_SHIPPING_LINK, response);
  return response;
}
