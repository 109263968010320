import { REQUIRES_TRACK_TRACE } from '@/router/route-guard/middleware/middleware';
import { beforeEnterTrackTrace } from '@/router/route-guard/beforeEnterTrackTrace';
import config from '@/helpers/config';
import { createPathWithUppercasedParameters } from '@/router/createPathWithUppercasedParameters';

/**
 * Track & Trace is split up into two routes because of this issue: https://stackoverflow.com/a/51087905/10225966.
 *
 * @type {Router.Route}
 */
export const trackTraceRoute = {
  name: 'TrackTrace',
  path: '/track-trace/:barcode/:postal_code/:cc?',
  alias: '/backoffice/track-trace/:barcode/:postal_code/:cc?',
  meta: {
    title: 'track_trace.title',
    middleware: [REQUIRES_TRACK_TRACE],
    hasHomeButton: true,
  },
  component: () => import('@/views/TrackTrace'),
  beforeEnter(to, from, next) {
    // Clone `to`, to be able to overwrite properties.
    const clonedTo = { ...to };

    // Make sure all parameters are uppercase.
    clonedTo.path = createPathWithUppercasedParameters(clonedTo);

    // When the postal code field is actually a cc, redirect to the route without a postal code.
    if (to.params.postal_code?.match(/^[A-Z]{2}$/)) {
      clonedTo.name = 'TrackTraceNoPostalCode';
    } else if (to.params.barcode && to.params.postal_code && !to.params.cc) {
      // Add the default cc to the path if it's missing.
      clonedTo.path += `/${config.defaultCC}`;
      clonedTo.params.cc = config.defaultCC;
    }

    if (clonedTo.path === to.path) {
      return beforeEnterTrackTrace(to, from, next);
    }

    next(clonedTo.path);
  },
};
