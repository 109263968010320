import { FLESPAKKET, MYPARCEL, SENDMYPARCEL } from '@/helpers/platform/data';
import { defaultSendMyParcelBranding } from '@/data/demo/branding/sendmyparcel';
import { defaultFlespakketBranding } from '@/data/demo/branding/flespakket';
import { defaultMyParcelBranding } from '@/data/demo/branding/myparcel';
import { platform } from '@/helpers/platform';

/**
 * Get branding depending on the current platform.
 *
 * @returns {Object} Branding.
 */
export function getDemoBranding() {
  const map = {
    [FLESPAKKET]: defaultFlespakketBranding,
    [MYPARCEL]: defaultMyParcelBranding,
    [SENDMYPARCEL]: defaultSendMyParcelBranding,
  };
  return map[platform];
}
