/* eslint-disable no-magic-numbers */
import {
  POSTNL,
  BPOST,
  CHEAP_CARGO,
  DPD,
  INSTABOX,
  DHL,
  BOL,
  UPS,
  DHL_FOR_YOU,
  DHL_PARCEL_CONNECT,
  DHL_EUROPLUS,
} from '@/helpers/carriers';

/**
 * Map carrier names to carrier_id from the API.
 *
 * @type {{name: string, id: number}[]}
 */
export const carrierMapping = [
  {
    id: 1,
    name: POSTNL,
  },
  {
    id: 2,
    name: BPOST,
  },
  {
    id: 3,
    name: CHEAP_CARGO,
  },
  {
    id: 4,
    name: DPD,
  },
  {
    id: 5,
    name: INSTABOX,
  },
  {
    id: 6,
    name: DHL,
  },
  {
    id: 7,
    name: BOL,
  },
  {
    id: 8,
    name: UPS,
  },
  {
    id: 9,
    name: DHL_FOR_YOU,
  },
  {
    id: 10,
    name: DHL_PARCEL_CONNECT,
  },
  {
    id: 11,
    name: DHL_EUROPLUS,
  },
];
