import { getLoadingPropertyName } from '@/store/helpers/getLoadingPropertyName';

/**
 * Creates an object with mutations to set loading to true or false by given names to avoid having to have a lot
 *  of nearly identical code.
 *
 * @example
 *  generateLoadingMutations(['socialShippingLink']);
 *  // {
 *  //   START_LOADING_SOCIAL_SHIPPING_LINK(state) {
 *  //      state.loadingSocialShippingLink = true;
 *  //   },
 *  //   STOP_LOADING_SOCIAL_SHIPPING_LINK(state) {
 *  //      state.loadingSocialShippingLink = false;
 *  //   },
 *  // }
 *
 * @param {String[]} properties
 *
 * @returns {Object}
 */
export function generateLoadingMutations(properties) {
  return ['START', 'STOP'].reduce((acc, type) => {
    properties.forEach((property) => {
      let prefix = '';
      let name = property;

      // Support for namespaced modules.
      if (property.includes('/')) {
        [, prefix, name] = (/(\w+\/)(\w+)/).exec(property);
      }

      acc[`${prefix}${type}_LOADING_${name}`] = (state) => {
        const propertyName = getLoadingPropertyName(name);
        state[propertyName] = type === 'START';
      };
    });

    return acc;
  }, {});
}
