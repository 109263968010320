import config from '@/helpers/config';
import { i18n } from '@/services/i18n';

/**
 * Search an array for an entry that matches the language. Falls back to defaultLanguage if it exists, otherwise returns
 * first item in the array.
 *
 * Use additionalFunction(data) to add search arguments.
 *
 * @param {Array} array - Array to search in.
 * @param {String} language - Language to search for in the first place.
 * @param {Function} additionalFunction - Additional search function.
 * @param {Boolean} allowFirst - Allow to fall back to first found entry.
 *
 * @returns {Object|undefined}
 */
export const findByLanguage = (array, language = i18n.locale, additionalFunction = null, allowFirst = true) => {
  const search = (searchLanguage) => array.find((data) => {
    const languageMatches = data.language === searchLanguage;

    if (additionalFunction) {
      return languageMatches && additionalFunction(data);
    }

    return languageMatches;
  });

  const firstItem = allowFirst ? array[0] : null;

  return search(language) || search(config.defaultLanguage) || firstItem;
};
