import { getVariables, portalDomain } from '@/helpers/platform';
import { getUrlWithoutSubdomain } from '@/router/getUrlWithoutSubdomain';

/**
 * Modifies a given link_consumer_portal url if needed to point to the correct environment.
 *
 * @param {string} link - Link to modify.
 *
 * @returns {string}
 */
export const createPortalRedirectUrl = (link) => {
  const redirectUrl = new URL(link);

  // Check if it's a link to the portal.
  if (redirectUrl.hostname.endsWith(portalDomain)) {
    const { subdomain } = getVariables(redirectUrl.host);
    const urlWithoutSubdomain = getUrlWithoutSubdomain(window.location.host);
    const newUrl = subdomain ? [subdomain, urlWithoutSubdomain].join('.') : urlWithoutSubdomain;

    // Create a new origin for the portal url, preserving protocol and host.
    return redirectUrl.href.replace(redirectUrl.origin, `${window.location.protocol}//${newUrl}`);
  }

  return link;
};
