export const vTest = (el, binding) => {
  /**
   * Add `data-test-*` attribute to elements if environment is test or when showTestLabels=true is set in localStorage.
   */
  if (process.env.NODE_ENV === 'test' || localStorage.getItem('showTestLabels')) {
    if (typeof binding.value === 'string') {
      el.setAttribute('data-test-id', binding.value);
      return;
    }

    Object.keys(binding.value).forEach((value) => {
      if (value === 'e2e') {
        el.setAttribute('data-test', binding.value[value]);
      } else {
        el.setAttribute(`data-test-${value}`, binding.value[value]);
      }
    });
  }
};
