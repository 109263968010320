import { BPOST } from '@/helpers/carriers';
import { createRelativeDate } from '@/data/demo/relativeDate';

export const bpost_demo = {
  barcode: '323211223344556677889900',
  carrier: BPOST,
  link_tracktrace: 'https://track.bpost.be/etr/light/performSearch.do?searchByItemCode=true&oss_language=NL&itemCodes=323211223344556677889900',
  code: 'N74',
  time: createRelativeDate('2019-04-08 09:49:00'),
  status: {
    current: 5,
    main: 'distribution',
    final: false,
  },
  recipient: {
    box_number: '',
    cc: 'BE',
    city: 'Antwerpen',
    company: 'MyParcel België',
    email: 'info@sendmyparcel.be',
    number: '16',
    number_suffix: '',
    person: 'Mr. Parcel',
    phone: '038089820',
    postal_code: '2000',
    street: 'Adriaan Brouwerstraat',
    street_additional_info: null,
  },
  options: {
    package_type: 1,
    only_recipient: 0,
    signature: 1,
    return: 0,
    delivery_type: 3,
    insurance: {
      amount: 0,
      currency: 'EUR',
    },
  },
  pickup: {
    location_name: 'bpost Antwerpen Eiermarkt',
    street: 'Eiermarkt',
    number: '33-35',
    postal_code: '2000',
    city: 'Antwerpen',
  },
  history: [{
    code: 'A03',
    status: 3,
    main: 'handed_to_carrier',
    time: createRelativeDate('2019-04-06 13:51:00'),
  },
  {
    code: 'T00',
    status: 4,
    main: 'sorting',
    time: createRelativeDate('2019-04-07 23:52:00'),
  },
  {
    code: 'L00',
    status: 5,
    main: null,
    time: createRelativeDate('2019-04-08 06:57:00'),
  }],
};
