import { POSTNL } from '@/helpers/carriers';
import { createRelativeDate } from '@/data/demo/relativeDate';

export const postnl_demo = {
  barcode: '3SMYPA000000000',
  carrier: POSTNL,
  time: createRelativeDate('2020-01-13 00:00:00'),
  link_consumer_portal: 'https://demo.myparcel.me/track-trace/3SMYPA000000000/2132WT/NL',
  link_tracktrace: 'https://postnl.nl/tracktrace/?B=3SMYPA000000000&P=2132WT&D=NL&T=C&L=NL',
  partner_tracktraces: [],
  code: 'S02',
  recipient: {
    cc: 'NL',
    city: 'Hoofddorp',
    street: 'Antareslaan',
    number: '31',
    postal_code: '2132JE',
    company: 'MyParcel',
    email: 'info@myparcel.nl',
    phone: '012345678',
    person: 'MyParcel',
    street_additional_info: '',
    number_suffix: '',
  },
  sender: {
    cc: 'NL',
    city: 'Hoofddorp',
    street: 'Antareslaan',
    number: '31',
    postal_code: '2132JE',
    company: 'MyParcel',
    email: 'info@myparcel.nl',
    phone: '012345678',
    person: 'MyParcel',
    street_additional_info: '',
    number_suffix: '',
  },
  options: {
    package_type: 1,
    signature: 0,
    age_check: 0,
    only_recipient: 0,
    return: 0,
    large_format: 0,
    cooled_delivery: 0,
    saturday_delivery: 0,
    insurance: {
      amount: 0,
      currency: 'EUR',
    },
    contribution: {
      amount: 0,
      currency: 'EUR',
    },
    label_description: '',
  },
  pickup: null,
  status: {
    current: 7,
    final: true,
    main: 'delivered',
  },
  history: [
    {
      code: 'B01',
      status: 2,
      main: null,
      time: createRelativeDate('2019-01-22 08:12:18'),
    }, {
      code: 'B01',
      status: 3,
      main: 'handed_to_carrier',
      time: createRelativeDate('2019-01-22 10:46:39'),
    }, {
      code: 'J10',
      status: 5,
      main: null,
      time: createRelativeDate('2019-01-22 12:21:42'),
    }, {
      code: 'J01',
      status: 4,
      main: 'sorting',
      time: createRelativeDate('2019-01-22 15:38:05'),
    }, {
      code: 'S02',
      time: createRelativeDate('2019-01-23 08:12:20'),
      status: 5,
      main: 'distribution',
    },
  ],
  delivery_moment_type: 'estimated',
  delivery_moment: {
    start: {
      date: createRelativeDate('2020-01-15 10:15:00.000000'),
      timezone_type: 3,
      timezone: 'Europe/Amsterdam',
    },
    end: {
      date: createRelativeDate('2020-01-15 12:45:00.000000'),
      timezone_type: 3,
      timezone: 'Europe/Amsterdam',
    },
  },
};
