import { router } from '@/router';

/**
 * Checks if the given (or current) route is in demo mode.
 *
 * @param {Route} route - Vue-router route.
 * @returns {Boolean}
 */
export function isDemo(route = router.currentRoute) {
  return route.meta.isDemo === true;
}
