<template>
  <Loader
    v-if="hasBranding && loadingBranding"
    type="full-screen"
    :loading="loadingBranding" />

  <div
    v-else
    class="md:tw-p-5 tw-max-w-screen-xl tw-mx-auto tw-p-3 tw-w-full">
    <div class="tw-flex tw-pb-3">
      <div
        v-if="$route.meta.hasHomeButton"
        class="tw-my-auto">
        <RouterLink
          class="hover:tw-text-primary-600 tw-duration-100 tw-text-primary-500 tw-transition-colors"
          to="/">
          <i class="i-home tw-mr-1" />
          <span v-t="'global.navigation_home'" />
        </RouterLink>
      </div>
      <LanguageSwitcher />
    </div>

    <Transition
      name="fade"
      mode="out-in">
      <RouterView />
    </Transition>
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher';
import { NO_BRANDING } from '@/router/route-guard/middleware/middleware';
import { hasMiddleware } from '@/router/route-guard/middleware/hasMiddleware';
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    LanguageSwitcher,
  },

  computed: {
    ...mapState(['loadingBranding', 'loadingFields']),

    /**
     * Check the middleware of the current route to see if branding and fields will be loaded.
     *
     * @returns {boolean}
     */
    hasBranding() {
      return !hasMiddleware(this.$route, NO_BRANDING);
    },
  },
};
</script>
