import {
  STORE_RETURN_COMMENT,
  STORE_RETURN_DATA,
  STORE_RETURN_REASONS,
  STORE_RETURN_REASON_SETTINGS,
} from '@/store/modules/returns/constants';
import { i18n } from '@/services/i18n';

export const mutations = {

  /**
   * Modify and save return data.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} returnData - Return data object.
   */
  [STORE_RETURN_DATA]: (state, returnData) => {
    state.returnData = returnData;
  },

  /**
   * @param {Object} state - Vuex state.
   * @param {Object} returnComment - Return comment.
   */
  [STORE_RETURN_COMMENT]: (state, returnComment) => {
    state.returnComment = returnComment;
  },

  /**
   * @param {Object} state
   * @param {Object} returnReasonSettings
   * @param {string} language - Language to save settings in.
   */
  [STORE_RETURN_REASON_SETTINGS]: (state, returnReasonSettings, language = i18n.locale) => {
    state.returnReasonSettings
      = state.returnReasonSettings.concat({
        ...returnReasonSettings,
        language,
      });
  },

  /**
   * @param {Object} state
   * @param {Object} returnReasons
   */
  [STORE_RETURN_REASONS]: (state, returnReasons) => {
    state.returnReasons = returnReasons;
  },
};
