import MyParcelApi from '@/services/MyParcelApi';
import { STORE_RETURN_REASONS } from '@/store/modules/returns/constants';

/**
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {string[]} payload
 */
export async function postReturnReasons({ commit }, payload) {
  const newReturnReasons = payload.map((name) => ({ name }));
  const returnReasons = await MyParcelApi.postReturnReasons(newReturnReasons);

  commit(STORE_RETURN_REASONS, returnReasons.map(({ name }) => name));
}
