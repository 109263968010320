import { defaultField } from '@/data/demo/fields/demoFields';
import { getDemoTranslatedFields } from '@/data/demo/fields/getDemoTranslatedFields';

export const defaultMyParcelFields = getDemoTranslatedFields({
  ...defaultField,
  banner_action_url: 'https://myparcel.nl',
  review_url: 'https://www.myparcel.nl/support/',
  company: {
    cc: 'NL',
    name: 'MyParcel',
    street: 'Antareslaan 31',
    street_additional_info: '',
    postal_code: '2132JE',
    city: 'Hoofddorp',
    email: 'info@myparcel.nl',
    phone: '023 3030315',
  },
  social_widget: {
    type: 'instagram_post',
    identifier: 'B7GgPRIFoS7',
  },
  social_links: {
    facebook: 'MyParcelNL',
    twitter: 'MyParcelNL',
    instagram: 'myparcel_nl',
    linkedin: 'myparcel',
  },
});
