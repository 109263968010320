const APPROXIMATE_WIDTH_MODIFIER = 0.75;

/**
 * Directive for use with SkeletonLoader.
 *
 * @param {HTMLElement} el - Element with the directive.
 * @param {Object} binding - Directive binding.
 * @param {string | number | string[] | number[]} binding.value - When number(s), uses that as width. When string(s),
 *  converts string to a number which should make the skeleton approximately the length of the original string.
 *
 * @returns {HTMLElement}
 */
export const vSkeleton = (el, binding) => {
  if (!binding.value) {
    return el;
  }

  const rows = Array.isArray(binding.value) ? binding.value : [binding.value];
  el.classList.add('skeleton', 'disabled');

  if (el instanceof HTMLButtonElement) {
    el.classList.add('skeleton--block');
    el.innerText = '';
  } else if (el instanceof HTMLTextAreaElement || el instanceof HTMLInputElement) {
    el.classList.add('skeleton--block');
    el.innerText = '';
    el.disabled = true;
  } else {
    el.classList.add('skeleton--inline');
  }

  rows.forEach((width) => {
    if (typeof width === 'string') {
      width = width.length * APPROXIMATE_WIDTH_MODIFIER;
    }

    const span = document.createElement('span');
    span.innerHTML = '&nbsp;';
    span.style.width = '100%';
    span.style.maxWidth = `${width}ch`;
    el.appendChild(span);
  });

  return el;
};
