import { createCssTemplate } from '@/services/branding/createCssTemplate';

/**
 * Add a style tag to document head with the fetched branding styles. Replace it if it is already present.
 *
 * @param {Object} colors - Colors object.
 *
 * @returns {Promise}
 */
export const addBranding = async(colors) => {
  let style = document.querySelector('style#branding');
  const exists = !!style;

  if (!exists) {
    style = document.createElement('style');
    style.setAttribute('id', 'branding');
  }

  style.innerHTML = await createCssTemplate(colors);

  if (!exists) {
    document.head.appendChild(style);
  }
};
