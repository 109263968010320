import { ApiError } from '@/services/ApiError';
import MyParcelApi from '@/services/MyParcelApi';
import { STORE_SOCIAL_SHIPPING_LINK } from '@/store/modules/social-shipping/constants';
import { demoSocialShippingLink } from '@/data/demo/socialShipping/demoSocialShippingLink';
import { isDemo } from '@/services/functions/isDemo';

export const fetchSocialShippingLink = async({ commit, state }) => {
  if (state.socialShippingLink) {
    return state.socialShippingLink;
  }

  commit('START_LOADING_SOCIAL_SHIPPING_LINK');

  let socialShippingLink;

  if (isDemo()) {
    socialShippingLink = demoSocialShippingLink;
  } else {
    try {
      socialShippingLink = await MyParcelApi.getSocialShippingLink();
    } catch (e) {
      return new ApiError(e);
    }
  }

  commit(STORE_SOCIAL_SHIPPING_LINK, socialShippingLink);
  commit('STOP_LOADING_SOCIAL_SHIPPING_LINK');

  return socialShippingLink;
};
