import { fetchBranding } from '@/store/actions/fetchBranding';
import { fetchFields } from '@/store/actions/fetchFields';
import { fetchTrackingInformation } from '@/store/actions/fetchTrackingInformation';
import { postRelatedReturnShipment } from '@/store/actions/postRelatedReturnShipment';

export default {
  FETCH_BRANDING: fetchBranding,

  FETCH_FIELDS: fetchFields,

  FETCH_TRACKING_INFORMATION: fetchTrackingInformation,

  POST_RELATED_RETURN_SHIPMENT: postRelatedReturnShipment,
};
