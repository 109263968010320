import { getMiddleware } from '@/router/route-guard/middleware/getMiddleware';
import { middleware } from '@/router/route-guard/middleware/middleware';

/**
 * Check if a given middleware item passes its condition(s), if none is given check all middleware.
 *
 * @param {Object} item - Optional middleware item to check instead of all.
 *
 * @returns {Boolean}
 */
export const checkMiddleware = (item = null) => {
  if (item) {
    const found = getMiddleware(item);

    return found && found.condition();
  }

  return middleware.every((middlewareItem) => middlewareItem.condition());
};
