const BPOST = 'bpost';
const CHEAP_CARGO = 'cheap_cargo';
const DHL = 'dhl';
const DPD = 'dpd';
const POSTNL = 'postnl';
const INSTABOX = 'instabox';
const BOL = 'bol';
const UPS = 'ups';
const DHL_FOR_YOU = 'dhlforyou';
const DHL_PARCEL_CONNECT = 'dhlparcelconnect';
const DHL_EUROPLUS = 'dhleuroplus';


// This is a commonJS module because /private/tailwind/carrierColorMapping.js uses it.
module.exports = {
  BPOST,
  CHEAP_CARGO,
  DHL,
  DPD,
  POSTNL,
  INSTABOX,
  BOL,
  UPS,
  DHL_FOR_YOU,
  DHL_PARCEL_CONNECT,
  DHL_EUROPLUS,
};
