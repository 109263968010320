import { ApiError } from '@/services/ApiError';
import MyParcelApi from '@/services/MyParcelApi';
import { STORE_RECIPIENT } from '@/store/modules/social-shipping/constants';
import {
  createRecipientFromAddressFinderData,
} from '@/store/modules/social-shipping/createRecipientFromAddressFinderData';
import { isDemo } from '@/services/functions/isDemo';
import { removeEmptyValues } from '@/store/helpers/removeEmptyValues';

/**
 * Update a social shipping link.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} address
 * @returns {Promise<ApiError|Object>}
 */
export async function findAddress({ commit }, address) {
  // Demo cannot make use of the findaddress because only calls from a subdomain matching a shop can call this endpoint.
  if (isDemo()) {
    return;
  }

  let foundAddress;

  const filteredAddress = removeEmptyValues(address);
  try {
    foundAddress = await MyParcelApi.findAddress(filteredAddress);

    if (!foundAddress) {
      return;
    }
  } catch (e) {
    return new ApiError(e);
  }

  const newRecipient = createRecipientFromAddressFinderData(foundAddress);

  commit(STORE_RECIPIENT, newRecipient);
  return foundAddress;
}
