import MyParcelApi from '@/services/MyParcelApi';
import { STORE_RETURN_DATA } from '@/store/modules/returns/constants';
import { demoReturnData } from '@/data/demo/returnData/demoReturnData';
import { isDemo } from '@/services/functions/isDemo';
import isEqual from 'lodash-es/isEqual';
import { router } from '@/router';

/**
 * Fetch return data. Gets demo data if hash is demo or existing data if data is requested again.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} context.state - Vuex state.
 *
 * @returns {Promise<Object>}
 */
export const postRelatedReturnShipment = async({ commit, state }) => {
  if (state.returnData && isEqual(router.currentRoute.params, state.returnData.related_shipment_data)) {
    return state.returnData;
  }

  commit('START_LOADING_RETURN_DATA');

  let returnData;

  if (isDemo()) {
    returnData = demoReturnData;
  } else {
    returnData = await MyParcelApi.postRelatedReturn();
  }

  // Transform carrier name for use in translation keys.
  returnData.carrier.name = returnData.carrier.name.toLowerCase();

  commit(STORE_RETURN_DATA, returnData);
  commit('STOP_LOADING_RETURN_DATA');

  return returnData;
};
