/**
 * This function recolors favicons to the branding accent color :).
 *
 * @param {Number} size - Size of the current icon.
 * @param {String} color - Color.
 */
export const recolorFavicon = (size, color) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }

  const link = document.querySelector(`link[sizes='${size}x${size}']`);
  const img = document.createElement('img');
  img.src = link.href;

  img.onload = () => {
    changeFaviconColor(color, size, { img, link });
  };
};

const changeFaviconColor = (color, size, { img, link }) => {
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;

  const context = canvas.getContext('2d');
  context.drawImage(img, 0, 0);
  context.globalCompositeOperation = 'source-in';
  context.fillStyle = color;
  context.fillRect(0, 0, size, size);
  context.fill();

  link.type = 'image/x-icon';
  link.href = canvas.toDataURL();
};
