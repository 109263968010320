/**
 * Split the string inherited style added by a functional component to create a style object out of it.
 *
 * @param {Object} component - Vue component 'this'.
 *
 * @returns {Object}
 */
export const getInheritedStyle = (component) => {
  let inheritedStyle = component.$attrs.style;
  let newStyle = {};

  if (inheritedStyle) {
    if (typeof inheritedStyle === 'string') {
      // Remove the last semicolon, if there are any, or else split will return an undefined value.
      inheritedStyle = inheritedStyle.replace(/;$/, '');

      newStyle = inheritedStyle.split(';').reduce((acc, val) => {
        const [attribute, value] = val.split(':');

        return {
          ...acc,
          [attribute.trim()]: value.trim(),
        };
      }, {});
    } else {
      newStyle = inheritedStyle;
    }
  }

  return newStyle;
};
