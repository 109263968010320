import { middleware } from '@/router/route-guard/middleware/middleware';

/**
 * Find a middleware item by name.
 *
 * @param {String} itemName - Name of middleware to search for.
 *
 * @returns {Object|null}
 */
export const getMiddleware = (itemName) => {
  return middleware.find(({ name }) => name === itemName);
};
