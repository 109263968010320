import Cookies from 'js-cookie';

/**
 * Get the preferred language from the cookies. If no such cookie exists get the language from the browser.
 *
 * @returns {String}
 */
export function getLanguageFromBrowser() {
  return Cookies.get('language') || window.navigator.userLanguage || window.navigator.language;
}
