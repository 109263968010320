import { actions } from '@/store/modules/returns/actions';
import { createStoreModule } from '@/store/helpers/createStoreModule';
import { getters } from '@/store/modules/returns/getters';
import { mutations } from '@/store/modules/returns/mutations';
import { state } from '@/store/modules/returns/state';

const loadingProperties = [
  'RETURN_COMMENT',
  'RETURN_DATA',
  'RETURN_REASONS',
  'RETURN_REASON_SETTINGS',
];

/**
 * Initializes the returns store module.
 *
 * @returns {import('vuex').StoreOptions}
 */
export function createReturnsStoreModule() {
  return createStoreModule(
    {
      state,
      mutations,
      getters,
      actions,
    },
    loadingProperties,
  );
}
