import Vue from 'vue';
import VueI18n from 'vue-i18n';
import config from '@/helpers/config';
import { getLanguageFromBrowser } from '@/services/i18n/getLanguageFromBrowser';
import { getLocaleCode } from '@/services/i18n/getLocaleCode';
import { isProd } from '@/helpers/environment';
import { loadLanguage } from '@/services/i18n/loadLanguage';

Vue.use(VueI18n);

export const loadedLanguages = [];

/**
 * @type {VueI18n}
 */
export let i18n;

/**
 * @returns {VueI18n}
 */
export function createI18n() {
  const language = getLocaleCode(getLanguageFromBrowser()) || config.defaultLanguage;

  i18n = new VueI18n({
    locale: language,

    // fallback locale for missing strings (if any)
    fallbackLocale: config.fallbackLanguage,
    formatFallbackMessages: true,

    // don't log warnings on production
    silentTranslationWarn: isProd,
    messages: setMessages(language),

    /**
     * @see https://kazupon.github.io/vue-i18n/guide/directive.html#use-with-transitions
     */
    preserveDirectiveContent: true,
  });

  return i18n;
}

/**
 * @param {String} language
 *
 * @returns {Object}
 */
async function setMessages(language) {
  let messages = await loadLanguage(language);

  if (language !== config.fallbackLanguage) {
    messages = { ...messages, ...await loadLanguage(config.fallbackLanguage) };
  }

  return messages;
}
