import { TW_500 } from '@/services/branding/constants';
import { getForegroundColor } from '@/services/branding/getForegroundColor';

/**
 * Replace variables in the branding template to create a stylesheet with the custom branding colors.
 *
 * @param {Object} colors
 *
 * @returns {Promise<string>}
 */
export async function createCssTemplate(colors) {
  // Use raw-loader to get it as plain text.
  let cssTemplate = (await import('!raw-loader!./branding.template.css')).default;

  const variables = {
    ...colors,
    FOREGROUND: getForegroundColor(colors[TW_500]),
  };

  Object
    .keys(variables)
    .forEach((variable) => {
      cssTemplate = cssTemplate.replace(new RegExp(`\\[${variable}\\]`, 'g'), variables[variable]);
    });

  return cssTemplate;
}
