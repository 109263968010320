const chroma = require('chroma-js');

const COLOR_STEPS = 9;

// Add two steps to not have to use white and black.
const ADDITIONAL_STEPS = 2;

/**
 * Creates a <color>-100 to <color>-900 range for use with Tailwind by using
 *  chroma-js to generate a gradient between white and black with the given
 *  color as center:
 *    white - [ 1 - 2 - 3 - 4 - 5 (given color) - 6 - 7 - 8 - 9 ] - black.
 *
 * @param {string} color
 *
 * @returns {Object}
 */
function createColorRange(color) {
  const colors = {};
  const scale = chroma
    .scale(['black', color, 'white'])
    .classes(COLOR_STEPS + ADDITIONAL_STEPS);

  let i = 0;
  for (let step = COLOR_STEPS; step >= 1; step--) {
    i++;
    const stepName = (i * 100).toString();

    // The scale goes from 0 to 1.
    // eslint-disable-next-line no-magic-numbers
    colors[stepName] = scale(step * 0.1).hex();
  }

  return colors;
}

module.exports = { createColorRange };
