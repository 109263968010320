import { checkMiddleware } from '@/router/route-guard/middleware/checkMiddleware';
import { getMiddleware } from '@/router/route-guard/middleware/getMiddleware';
import log from '@/helpers/log';

/**
 * Check if all middleware pass, if so, proceed using the passed next() function. If not, run the failing middleware's
 *  onFail function.
 *
 * @param {Object} to - Router destination.
 * @param {Object} from - Previous route.
 * @param {Function} next - Redirect function.
 */
export const applyMiddleware = (to, from, next) => {
  const middlewarePassed = !to.meta.middleware || to.meta.middleware.every((item) => {
    if (!checkMiddleware(item)) {
      const middleware = getMiddleware(item);

      // Skip if middleware isn't found.
      if (!middleware) {
        log.warning(`Middleware "${item}" was called but it doesn't exist.`);
        return true;
      }

      middleware.onFail(to, from);

      return false;
    }

    return true;
  });

  if (middlewarePassed) {
    next();
  }
};
