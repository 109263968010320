import {
  STORE_DELIVERY_OPTIONS,
  STORE_RECIPIENT,
  STORE_SOCIAL_SHIPPING_LINK,
} from '@/store/modules/social-shipping/constants';
import { fetchSocialShippingLink } from '@/store/modules/social-shipping/actions/fetchSocialShippingLink';
import { findAddress } from '@/store/modules/social-shipping/findAddress';
import { putSocialShippingLink } from '@/store/modules/social-shipping/putSocialShippingLink';

export const storeData = {
  state: {
    socialShippingLink: null,
    recipient: {},
    deliveryOptions: null,
  },

  mutations: {
    /**
     * @param {Object} state
     * @param {Object} socialShippingLink
     */
    [STORE_SOCIAL_SHIPPING_LINK]: (state, socialShippingLink) => {
      state.socialShippingLink = socialShippingLink;
    },

    /**
     * @param {Object} state
     * @param {Object} recipient
     */
    [STORE_RECIPIENT]: (state, recipient) => {
      state.recipient = {
        ...state.recipient,
        ...recipient,
      };
    },

    /**
     * @param {Object} state
     * @param {Object} deliveryOptions
     */
    [STORE_DELIVERY_OPTIONS]: (state, deliveryOptions) => {
      state.deliveryOptions = deliveryOptions;
    },
  },

  getters: {
    recipient(state) {
      return {
        ...state.socialShippingLink?.shipment_options?.recipient,
        ...state.recipient,
      };
    },
  },

  actions: {
    fetchSocialShippingLink,
    putSocialShippingLink,
    findAddress,
  },
};
