import { findByLanguage } from '@/services/findByLanguage';
import { i18n } from '@/services/i18n';

export const getters = {
  /**
   * @param {Object} state - Vuex state.
   *
   * @returns {Function}
   */
  returnReasonSettings: (state) => {
    return findByLanguage(state.returnReasonSettings, i18n.locale);
  },
};
