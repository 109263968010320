<template>
  <img
    :alt="language.name"
    :title="language.name"
    class="flag mr-2 tw-inline tw-w-6"
    :src="`${$config.flagPath}${language.country}.png`">
</template>

<script>
export default {
  name: 'Flag',
  props: {
    language: {
      type: Object,
      default: null,
    },
  },
};
</script>
