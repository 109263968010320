import dayjs from 'dayjs';

const currentYear = dayjs().year();

/**
 * Parse a date but set the year to current and the month to last.
 *
 * @param {String} date - Date to use as a base.
 * @returns {String}
 */
export function createRelativeDate(date) {
  return dayjs(date).year(currentYear).subtract(1, 'month').format();
}
