import { router } from '@/router';

/**
 * Gets the correct redirect link from a given response object, depending on the current route.
 *
 * @param {Object} response
 *
 * @returns {String}
 */
export function getRedirectLinkFromResponse(response) {
  switch (router.currentRoute.name) {
    case 'SocialShipping':
      return response.link_social_shipping;
    case 'TrackTrace':
      return response.link_track_trace || response.link_consumer_portal;
    default:
      return response.link_consumer_portal;
  }
}
