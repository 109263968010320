import {
  LOADING_TYPES,
  STORE_BRANDING,
  STORE_FIELDS,
  STORE_TRACKING_INFORMATION,
} from '@/store/mutation-types';
import { TW_500 } from '@/services/branding/constants';
import { addBranding } from '@/services/branding/addBranding';
import config from '@/helpers/config';
import { createColorRange } from '../../private/tailwind/createColorRange';
import { defaultMyParcelBranding } from '@/data/demo/branding/myparcel';
import { generateLoadingMutations } from '@/store/helpers/generateLoadingMutations';
import { i18n } from '@/services/i18n';
import { recolorFavicon } from '@/helpers/recolorFavicon';

const mutations = {
  ...generateLoadingMutations(LOADING_TYPES),

  /**
   * Save new branding data to the state and create and apply styles based on the accent color.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} branding - Branding object from the API.
   *
   * @returns {Promise}
   */
  [STORE_BRANDING]: (state, branding) => {
    // Remove some unneeded keys from branding object
    const { id, created, shop_id, modified, ...newBranding } = branding;

    state.branding = newBranding;

    // Create color map from the accent color
    const colors = createColorRange(branding.accent_color || defaultMyParcelBranding.accent_color);

    // Recolor the favicon(s) to the accent color
    config.iconSizes.map((size) => recolorFavicon(size, colors[TW_500]));

    // Create and add a style tag with the branding styles.
    return addBranding(colors);
  },

  /**
   * Save new fields to the state.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} fields - Fields object to save.
   */
  [STORE_FIELDS]: (state, fields) => {
    state.fields = state.fields.concat(fields);
  },

  /**
   * Modify and save tracking information.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} trackingInformation - Tracking information object.
   * @param {string} language - Language to add to object.
   */
  [STORE_TRACKING_INFORMATION]: (state, trackingInformation, language = i18n.locale) => {
    state.trackingInformation = state.trackingInformation.concat({
      ...trackingInformation,
      options: {
        ...trackingInformation.options,
        shown_options: [],
      },
      language,
    });
  },
};

export default mutations;
