import { ApiError } from '@/services/ApiError';
import config from '@/helpers/config';
import { isProd } from '@/helpers/environment';
import log from '@/helpers/log';

/**
 * Redirect to given page if on prod, if not just show the error.
 *
 * @param {Object} error - Error/data to log.
 * @param {String} location - URL to redirect to. Defaults to 404 link from config.
 */
export const redirect = (error = null, location = config.url404) => {
  let message = error;

  if (!isProd) {
    log.info(`Redirecting to: ${location}`);

    if (error instanceof ApiError) {
      const status = error.getStatus() ? `${error.getStatus()}: ` : '';
      message = `${status}${error.getMessage()} (Code ${error.getCode()})`;
    } else if (error instanceof Error) {
      message = error.message;
    }

    if (message) {
      log.warning(message);
    }
  }

  // eslint-disable-next-line no-alert
  if (isProd || confirm(`Redirecting to:\n${location}${message ? `\n\nReason: ${message}` : ''}`)) {
    window.location.assign(location);
  }
};
