import { defaultField } from '@/data/demo/fields/demoFields';
import { getDemoTranslatedFields } from '@/data/demo/fields/getDemoTranslatedFields';

export const defaultFlespakketFields = getDemoTranslatedFields({
  ...defaultField,
  banner_action_url: 'https://flespakket.nl',
  company: {
    cc: 'NL',
    name: 'Flespakket',
    city: 'Hoofddorp',
    email: 'info@flespakket.nl',
    phone: '023 30 31 818',
  },
  social_widget: {
    type: 'instagram_post',
    identifier: 'BvhEpsbHJMc',
  },
  social_links: {
    facebook: 'flespakketNL',
    instagram: 'flespakket',
  },
});
