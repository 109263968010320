/* eslint-disable no-console */
import { isProd } from '@/helpers/environment';

const log = {
  success(message) {
    this.createMessage(message, ['#23b237']);
  },

  info(message) {
    this.createMessage(message, ['#34b7d4']);
  },

  warning(...message) {
    this.createMessage(message, [], 'warn');
  },

  error(message) {
    this.createMessage(message, [], 'error');
  },

  createMessage(message, style = [], consoleFunction = 'log') {
    if (isProd) {
      return;
    }

    if (message instanceof Error) {
      console.error(message);
      return;
    } else if (typeof message !== 'string') {
      console[consoleFunction](...message);
      return;
    }

    console.log(`%c${message}`, this.style.apply(null, style));
  },

  style(bgc, color = 'white', padding = '1px .5em') {
    return `background-color: ${bgc}; color: ${color}; border-radius: 2px;${padding ? `padding: ${padding};` : ''}`;
  },
};

export default log;
