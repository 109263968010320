import config from '@/helpers/config';
import { findByLanguage } from '@/services/findByLanguage';
import { i18n } from '@/services/i18n';
import { isDemo } from '@/services/functions/isDemo';
import { router } from '@/router';

export default {
  /**
   * The translatable fields the user has filled in. Returns fields in current language and falls back to default
   *  language if the current one is not available.
   *
   * @param {Object} state - Vuex state.
   *
   * @returns {Function}
   */
  fields: (state) => {
    return findByLanguage(state.fields, i18n.locale);
  },

  /**
   * Tracking information for `barcode`, `postal_code` and `country` in route parameters. Returns fake data if route
   * name is TrackTraceDemo, otherwise uses the `all_barcodes` getter to fetch data in current language.
   *
   * @param {Object} state - Vuex state.
   *
   * @returns {Object} - `trackingInformation[<locale>][<requested shipment>]`: Tracking information.
   */
  currentTrackingInformation: (state) => {
    const { locale } = i18n;
    const { params } = router.currentRoute;

    const checkBarcode = (data) => {
      // Don't check for the barcode if this is the track & trace demo
      return isDemo() || data.barcode === params.barcode;
    };

    let data = findByLanguage(state.trackingInformation, locale, checkBarcode, true);

    // If nothing matches by language AND barcode, only check for matching barcode.
    if (!data) {
      data = state.trackingInformation.find(checkBarcode);
    }

    const shownOptions = Object.keys(data.options).filter((option) => {
      return config.visibleOptions.includes(option) && data.options[option] !== 0;
    });

    return {
      ...data,
      options: {
        ...data.options,
        shown_options: shownOptions,
      },
    };
  },
};
