import { getValueFromMap } from '@/helpers/getValueFromMap';
import { carrierMapping } from '@/helpers/carrierMapping';

/**
 * Helper function to map carrier id to name.
 *
 * @param {number | string} carrier - Carrier id or name.
 * @returns {Object} - Carrier object.
 */
export function getCarrier(carrier) {
  return getValueFromMap(carrier, carrierMapping);
}
