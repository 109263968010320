import { dayjs } from '@/services/dayjs';

export const demoSocialShippingLink = {
  uuid: 'demo',
  shop_id: 1,
  generated_shipment_id: null,
  shipment_options: {
    reference_identifier: 'FOO-222-BAR-42',
    carrier: 1,
    recipient: {
      cc: 'NL',
    },
    options: {
      package_type: 1,
      only_recipient: 0,
      signature: 0,
      return: 0,
      insurance: {
        amount: 0,
        currency: 'EUR',
      },
      large_format: 0,
      label_description: 'My social shipment',
    },
  },
  link_track_trace: null,
  link_social_shipping: 'https://demo.myparcel.me/ship/demo',
  expires_at: {
    date: dayjs().add(1, 'month'),
    timezone_type: 3,
    timezone: 'Europe/Amsterdam',
  },
  created_at: {
    date: dayjs().subtract(1, 'day'),
    timezone_type: 3,
    timezone: 'Europe/Amsterdam',
  },
  updated_at: {
    date: dayjs().subtract(1, 'day'),
    timezone_type: 3,
    timezone: 'Europe/Amsterdam',
  },
};
