import { MiddlewareException } from '@/router/route-guard/exceptions/MiddlewareException';
import log from '@/helpers/log';

export class MiddlewareItem {
  name;
  condition;
  onFail;

  /**
   * @param {String} name - Name of the middleware.
   * @param {Function} condition - Condition it needs to pass.
   * @param {Function} onFail - Function on failing the condition.
   */
  constructor(name, condition, onFail = null) {
    this.name = name;
    this.condition = condition;
    this.onFail = onFail || (() => {
      log.error(`Middleware validation failed: "${name}"`);
      throw new MiddlewareException(name);
    });
  }
}
