<script>
/* eslint-disable no-magic-numbers */

export default {
  name: 'DelayedTransitionGroup',
  functional: true,

  props: {
    name: {
      type: String,
      default: 'grid',
    },

    appear: {
      type: Boolean,
    },

    /**
     * Interval in ms between transitions.
     */
    interval: {
      type: [Number, String],
      default: 30,
    },

    /**
     * Delay in ms before the first transition starts.
     */
    delay: {
      type: [Number, String],
      default: 0,
    },
  },

  render(createElement, context) {
    let { delay, interval } = context.props;

    // Convert to allow string values.
    delay = Number(delay);
    interval = Number(interval);

    if (context.children) {
      /**
       * Add a transition delay to each child of the component.
       */
      context.children = context.children.map((child, index) => {
        if (!child.data) {
          return child;
        }

        const transitionDelay = delay + (interval * index);

        // Only add the transition-delay property if it will have any effect.
        if (transitionDelay !== 0) {
          child.data.attrs = {
            ...child.data.attrs,
            style: `transition-delay: ${transitionDelay}ms`,
          };
        }

        return child;
      });
    }

    // Add default props.
    context.data.props = {
      ...context.data.props,
      ...context.props || {},
    };

    return createElement('transition-group', context.data, context.children);
  },
};
</script>
