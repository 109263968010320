/**
 * Deletes the key and values when the value is empty.
 *
 * @param {Object} payload
 * @returns {Object}
 */
export const removeEmptyValues = (payload) => {
  Object.keys(payload).forEach((key) => {
    const value = payload[key];
    const isDefined = typeof value === 'undefined';
    const isNull = value === null;

    if (isDefined || isNull) {
      delete payload[key];
    }
  });

  return payload;
};
