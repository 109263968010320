/**
 * Transform a route object into a string path with all parameters converted to uppercase.
 *
 * @param {Object} route - Route object.
 *
 * @returns {String}
 */
export function createPathWithUppercasedParameters(route) {
  // Add | between all words that should be replaced
  const search = Object.values(route.params).join('|');

  return route.path.replace(new RegExp(search, 'g'), (matched) => matched.toUpperCase());
}
