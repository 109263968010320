import MyParcelApi from '@/services/MyParcelApi';
import { STORE_RETURN_REASONS } from '@/store/modules/returns/constants';
import { isDemo } from '@/services/functions/isDemo';

/**
 * Fetch return reasons attached to current shipment.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} context.state - Vuex state.
 *
 * @returns {Promise<Object>}
 */
export const fetchReturnReasons = async({ commit, state }) => {
  if (isDemo()) {
    return [];
  }

  if (state.returnReasons || state.returnReasonSettings.some(({ enabled }) => enabled === true)) {
    return state.returnReasons;
  }

  commit('START_LOADING_RETURN_REASONS');

  const returnReasons = await MyParcelApi.getReturnReasons();
  const reasonNames = returnReasons.map(({ name }) => name);

  commit(STORE_RETURN_REASONS, reasonNames);
  commit('STOP_LOADING_RETURN_REASONS');

  return reasonNames;
};
