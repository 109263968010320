import { FLESPAKKET, MYPARCEL, SENDMYPARCEL } from '@/helpers/platform/data';
import { defaultFlespakketFields } from '@/data/demo/fields/flespakket';
import { defaultMyParcelFields } from '@/data/demo/fields/myparcel';
import { defaultSendMyParcelFields } from '@/data/demo/fields/sendmyparcel';
import { platform } from '@/helpers/platform';

export const defaultField = {
  banner_button_enabled: '0',
  banner_path: 'skin/images/favicon/ms-icon-310x310.png',
};

const map = {
  [FLESPAKKET]: defaultFlespakketFields,
  [MYPARCEL]: defaultMyParcelFields,
  [SENDMYPARCEL]: defaultSendMyParcelFields,
};

export const demoFields = map[platform];
