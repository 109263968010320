import MyParcelApi from '@/services/MyParcelApi';
import { STORE_TRACKING_INFORMATION } from '@/store/mutation-types';
import { formatTrackingInformation } from '@/store/helpers/formatTrackingInformation';
import { i18n } from '@/services/i18n';

/**
 * Gets actual tracking information from the api using barcode, postal_code and country_code.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} context.state - Vuex state.
 *
 * @param {Object} data - Passed data.
 *
 * @returns {Promise<Object>|Object}
 */
export const fetchTrackingInformation = async({ commit, state }, data) => {
  const found = state.trackingInformation.find((item) => {
    return item.barcode === data.barcode && item.language === i18n.locale;
  });

  if (found) {
    return found;
  }

  const options = {
    barcode: data.barcode,
    country_code: data.cc,
  };

  if (data.postal_code) {
    options.postal_code = data.postal_code;
  }

  commit('START_LOADING_TRACKING_INFORMATION');

  const response = await MyParcelApi.trackShipment(options);
  const formattedResponse = formatTrackingInformation({ ...response, ...options });

  commit(STORE_TRACKING_INFORMATION, formattedResponse);
  commit('STOP_LOADING_TRACKING_INFORMATION');

  return formattedResponse;
};
