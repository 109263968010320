import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import config from '@/helpers/config';
import { isDev } from '@/helpers/environment';
import { router } from '@/router';

Vue.use(VueGtm, {
  id: 'GTM-NWZZWRT',
  enabled: document.cookie.includes(config.consentCookieName),
  debug: isDev,
  vueRouter: router,
});
