import { dateFormats } from '@/services/dayjs';
import dayjs from 'dayjs';

/*
 * The dates are formatted like this explicitly because this is the format dayjs will try to parse in the
 *  ReturnsHeader.vue component.
 */
const returnDateFormat = dateFormats.withoutMs;

export const demoReturnData = {
  email_label: null,
  hash: 'demo',
  carrier: {
    id: 1,
    name: 'postnl',
    location_picker_url: 'https://www.postnl.nl/locatiewijzer/',
  },
  email: {
    email: 'email@adres.nl',
    name: 'Rylie',
  },
  created: {
    date: dayjs().format(returnDateFormat),
  },
  expire: {
    date: dayjs().add(1, 'month').format(returnDateFormat),
  },
  payment_instructions: null,
  related_shipment_data: null,
  has_printerless_return: false,

  /*
   * TODO: MY-24399 When everything is live, enable T&T x Returns in the demo by replacing "related_shipment_data: null"
   *  with the commented code below to make it show in the demo.
   */

  // related_shipment_data: {
  //   barcode: 'demo',
  //   postal_code: 'demo',
  //   cc: 'demo',
  // },
};
