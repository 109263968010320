import { createStoreModule } from '@/store/helpers/createStoreModule';
import { storeData } from '@/store/modules/social-shipping/storeData';

const loadingProperties = [
  'SOCIAL_SHIPPING_LINK',
];

/**
 * Initializes the social shipping store module.
 *
 * @returns {import('vuex').StoreOptions}
 */
export function createSocialShippingStoreModule() {
  return createStoreModule(
    storeData,
    loadingProperties,
  );
}
