/**
 * Get a matching entry from given map with either a name or id.
 *
 * @param {String|Number} value
 * @param {Object[]} map
 *
 * @returns {Object}
 */
export function getValueFromMap(value, map) {
  if (typeof value === 'string') {
    return map.find(({ name }) => name === value);
  }

  return map.find(({ id }) => id === value);
}
