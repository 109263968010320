<template>
  <component :is="tag">
    <slot style="will-change: height" />
  </component>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow';

/**
 * @see https://github.com/guanzo/vue-smooth-reflow#smooth-component
 */
export default {
  name: 'SmoothReflow',
  mixins: [smoothReflow],
  props: {
    tag: {
      type: String,
      default: 'div',
    },

    /**
     * @see https://github.com/guanzo/vue-smooth-reflow#options-reference
     */
    options: {
      type: [Object, Array],
      default: null,
    },

    showOverflow: {
      type: Boolean,
    },
  },

  mounted() {
    this.$smoothReflow({
      property: 'height',
      hideOverflow: !this.showOverflow,
      ...this.options,
    });
  },
};
</script>
