import { MiddlewareItem } from '@/router/route-guard/middleware/MiddlewareItem';
import { redirectWithoutSubdomain } from '@/router/redirectWithoutSubdomain';
import { store } from '@/store';
import { subdomain } from '@/helpers/platform';

/**
 * Used for routes that are only accessible without being on a branded subdomain.
 *
 * @type {String}
 */
export const NO_BRANDING = 'noBranding';

export const REQUIRES_BRANDING = 'requiresBranding';

export const REQUIRES_RETURNS = 'requiresReturns';

export const REQUIRES_TRACK_TRACE = 'requiresTrackTrace';

export const middleware = [
  new MiddlewareItem(
    NO_BRANDING,
    () => !subdomain,
    (to) => redirectWithoutSubdomain(NO_BRANDING, to),
  ),
  new MiddlewareItem(
    REQUIRES_BRANDING,
    () => !!subdomain,
  ),
  new MiddlewareItem(
    REQUIRES_RETURNS,
    () => store.state.branding.enable_returns === true,
    (to) => redirectWithoutSubdomain(REQUIRES_RETURNS, to),
  ),
  new MiddlewareItem(
    REQUIRES_TRACK_TRACE,
    () => store.state.branding.enable_track_trace === true,
  ),
];
