import axios from '@/services/axios';
import config from '@/helpers/config';
import { getLanguageFromBrowser } from '@/services/i18n/getLanguageFromBrowser';
import { getLocaleCode } from '@/services/i18n/getLocaleCode';
import { i18n } from '@/services/i18n';
import { loadLanguage } from './loadLanguage';
import { setDayJsLanguage } from '@/services/dayjs';

/**
 * Do everything needed to change the language. Will set language if supported, in this order:
 *  - The first valid locale in `languages`
 *  - The `language` cookie
 *  - Browser language
 *  - The default language from the config.
 *
 * @param {String[]} languages - ISO country code. First valid value will be used.
 *
 * @returns {Promise<String>}
 */
export async function loadAndSetLanguage(languages) {
  languages.push(getLanguageFromBrowser());

  const localeCodes = languages
    .filter(Boolean)
    .map(getLocaleCode);

  const [firstFoundLanguage] = localeCodes.filter(Boolean);
  const locale = firstFoundLanguage || config.defaultLanguage;

  await Promise.all([
    loadLanguage(locale),
    setDayJsLanguage(locale),
  ]);

  i18n.locale = locale;
  document.querySelector('html').setAttribute('lang', locale);
  axios.defaults.headers.get['Accept-Language'] = locale;

  return locale;
}
