import { ApiError } from '@/services/ApiError';
import axios from 'axios';
import config from '@/helpers/config';
import log from '@/helpers/log';

const instance = axios.create({
  baseURL: config.apiURL,
  headers: {
    // eslint-disable-next-line quote-props
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const time = {};
instance.interceptors.request.use((config) => {
  time.start = Date.now();
  return config;
});

instance.interceptors.response.use((response) => {
  time.end = Date.now();
  time.taken = time.end - time.start;
  log.success(`Done loading after ${time.taken}ms`);
  return response;
}, (error) => {
  return Promise.reject(new ApiError(error.response));
});

export default instance;
