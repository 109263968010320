import Vue from 'vue';
import { dateFormats } from '@/services/dayjs';
import { i18n } from '@/services/i18n';

/**
 * Use dayjs to filter a date in given format. If date is an array the first item is expected to be the date, and the
 *  second one the parsing format. This allows us to pass a format explicitly to fix bugs in (usually) Safari.
 *
 * Looks for milliseconds in the date string to decide which format to use when no dateFormat is passed.
 *
 * @param {String|Array} date - Date to convert.
 * @param {String} format - Output format. If this is a valid translation key it's translated by i18n.
 *
 * @returns {String}
 */
export const dayjsFilter = (date, format) => {
  let dateFormat;

  if (Array.isArray(date)) {
    [date, dateFormat] = date;
  }

  if (!dateFormat) {
    dateFormat = date.endsWith('.000000')
      ? dateFormats.withMs
      : dateFormats.withoutMs;
  }

  // Check if the given string is a translation, if so, translate it.
  format = i18n.te(format) ? i18n.t(format) : format;

  return Vue.prototype.$dayjs(date, dateFormat).format(format);
};
