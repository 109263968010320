import MyParcelApi from '@/services/MyParcelApi';
import { STORE_RETURN_REASON_SETTINGS } from '@/store/modules/returns/constants';
import { defaultReturnReasonSettings } from '@/data/default/returnReasonSettings/defaultReturnReasonSettings';
import { demoReturnReasonSettings } from '@/data/demo/returnData/demoReturnReasonSettings';
import { findByLanguage } from '@/services/findByLanguage';
import { i18n } from '@/services/i18n';
import { isDemo } from '@/services/functions/isDemo';

/**
 * Fetch return data. Returns if data is already present in requested language.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} context.state - Vuex state.
 *
 * @returns {Promise<Object>}
 */
export const fetchReturnReasonSettings = async({ commit, state }) => {
  const existingSettings = findByLanguage(state.returnReasonSettings);
  const returnReasonsDisabled = state.returnReasonSettings.length && state.returnReasonSettings.every(({ enabled }) => {
    return enabled === false;
  });

  // findByLanguage also returns results with the default locale, but that does not mean it exists.
  if ((existingSettings && existingSettings.language === i18n.locale) || returnReasonsDisabled) {
    return existingSettings;
  }

  commit('START_LOADING_RETURN_REASON_SETTINGS');

  let returnReasonSettings;
  let returnReasons;

  if (isDemo()) {
    returnReasonSettings = demoReturnReasonSettings;
    returnReasons = returnReasonSettings.return_reasons.map((name) => ({
      name,
      human: i18n.t(`returns.reasons.${name}`),
    }));
  } else {
    try {
      returnReasonSettings = await MyParcelApi.getReturnReasonSettings();
    } catch (e) {
      returnReasonSettings = defaultReturnReasonSettings;
    }
    returnReasons = [...returnReasonSettings.return_reasons];
  }

  // Move "other" reason to the bottom.
  returnReasons.push(...returnReasons.splice(returnReasons.findIndex((item) => item.name === 'other'), 1));

  const settings = {
    ...returnReasonSettings,
    return_reasons: returnReasons,
    language: i18n.locale,
  };

  commit(STORE_RETURN_REASON_SETTINGS, settings);
  commit('STOP_LOADING_RETURN_REASON_SETTINGS');

  return settings;
};
