import { ApiError } from '@/services/ApiError';
import MyParcelApi from '@/services/MyParcelApi';
import { STORE_RETURN_DATA } from '@/store/modules/returns/constants';
import { demoReturnData } from '@/data/demo/returnData/demoReturnData';
import { isDemo } from '@/services/functions/isDemo';

/**
 * Fetch return data. Gets demo data if hash is demo or existing data if data is requested again.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} context.state - Vuex state.
 *
 * @returns {Promise<Object>}
 */
export const fetchReturnData = async({ commit, state }) => {
  if (state.returnData) {
    return state.returnData;
  }

  commit('START_LOADING_RETURN_DATA');

  let returnData;

  if (isDemo()) {
    returnData = demoReturnData;
  } else {
    try {
      returnData = await MyParcelApi.getReturnData();
    } catch (e) {
      return new ApiError(e);
    }
  }

  // Transform carrier name for use in translation keys.
  returnData.carrier.name = returnData.carrier.name.toLowerCase();

  commit(STORE_RETURN_DATA, returnData);
  commit('STOP_LOADING_RETURN_DATA');

  return returnData;
};
