import { fetchReturnComment } from '@/store/modules/returns/actions/fetchReturnComment';
import { fetchReturnData } from '@/store/modules/returns/actions/fetchReturnData';
import { fetchReturnReasonSettings } from '@/store/modules/returns/actions/fetchReturnReasonSettings';
import { fetchReturnReasons } from '@/store/modules/returns/actions/fetchReturnReasons';
import { postReturnComment } from '@/store/modules/returns/actions/postReturnComment';
import { postReturnReasons } from '@/store/modules/returns/actions/postReturnReasons';

export const actions = {
  fetchReturnComment,
  fetchReturnData,
  fetchReturnReasonSettings,
  fetchReturnReasons,
  postReturnComment,
  postReturnReasons,
};
