import { FLESPAKKET, MYPARCEL, SENDMYPARCEL } from '@/helpers/platform/data';
import { createPortalRedirectUrl } from '@/router/route-guard/createPortalRedirectUrl';
import { getRedirectLinkFromResponse } from '@/router/route-guard/getRedirectLinkFromResponse';
import log from '@/helpers/log';
import { redirect } from '@/router/redirect';
import { subdomain } from '@/helpers/platform';

/**
 * Redirect to the correct url based on the link_consumer_portal in the given response.
 *
 * @param {Object} response - Response to check for link_consumer_portal.
 * @param {Function} next - Redirect function.
 *
 * @returns {boolean} - Whether or not the function redirected to a different url.
 */
export function redirectToCorrectUrl(response, next = () => null) {
  if (!response) {
    return false;
  }

  const link = getRedirectLinkFromResponse(response);
  const hasLink = Boolean(link);

  // If there is no consumer portal link, just proceed.
  if (!hasLink) {
    next();
    return false;
  }

  // Split the link's host on dots and get the first part to get the subdomain.
  let foundSubdomain = new URL(link).host.split('.')[0];

  if ([MYPARCEL, FLESPAKKET, SENDMYPARCEL].includes(foundSubdomain)) {
    foundSubdomain = null;
  }

  // If the current link is the same, continue.
  if (foundSubdomain === subdomain) {
    log.info('Subdomain matches');
    next();
    return false;
  }

  // Otherwise, redirect to the correct subdomain.
  redirect('Response has different redirect url', createPortalRedirectUrl(link));
  return true;
}
