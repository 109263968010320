import axios from '@/services/axios';
import config from '@/helpers/config';
import memoize from 'lodash-es/memoize';
import { router } from '@/router';
import { subdomain } from '@/helpers/platform';

const MyParcelApi = {
  urls: {
    billing__payment_status: '/billing/payment_status/{hash}',
    branding: '/consumer_portal/branding',
    ers_country_codes: '/ers_country_codes',
    fields: '/consumer_portal/fields',
    locations: '/consumer_portal/locations',
    phone_number_check: '/phone_number/check',
    post_return_shipments: '/consumer_portal/return_shipments',
    postal_code_check: '/postal_code/check',
    printerless_return_label: '/printerless_return_label',
    return_reason_settings: '/consumer_portal/return_reason_settings',
    return_shipment: '/return_shipment/{hash}',
    return_shipment__email_label: '/return_shipment/email_label',
    return_shipment__return_comment: '/return_shipment/{hash}/return_comment',
    return_shipment__return_reasons: '/return_shipment/{hash}/return_reasons',
    return_shipment_labels: '/return_shipment_labels',
    social_shipping_links: '/consumer_portal/social_shipping_links/{uuid}',
    track_trace: '/tracktraces',
  },

  cached: {
    get: memoize(axios.get),
  },

  /**
   * Get branding settings.
   *
   * Endpoint: /consumer_portal/branding.
   *
   * @returns {Object}
   */
  async getBranding() {
    const url = this.buildURL(this.urls.branding, { subdomain });
    const response = await axios.get(url);

    return response.data.data.branding[0];
  },

  /**
   * Attach return reasons to a return shipment by hash.
   *
   * @param {Object} returnReasons
   * @returns {Promise<Object[]>}
   */
  async postReturnReasons(returnReasons) {
    const url = this.getUrlWithHash(this.urls.return_shipment__return_reasons);
    const response = await axios.post(url, {
      data: {
        return_reasons: returnReasons,
      },
    });

    return response.data.data.return_reasons;
  },

  /**
   * Get fields by language for shop.
   *
   * Endpoint: /consumer_portal/fields/{id}.
   *
   * @param {string} language - Language to get fields for.
   *
   * @returns {Object}
   */
  async getFields(language) {
    const url = this.buildURL(this.urls.fields, { language, subdomain });
    const response = await axios.get(url, {
      headers: {
        accept: 'application/json;version=2.0',
      },
    });

    return response.data.data.fields[0];
  },

  /**
   * Track shipment.
   * Query arguments: barcode       {options.barcode}
   *                  postal_code   {options.postal_code}
   *                  country_code  {options.country_code}
   *                  extra_info    delivery_moment
   *                  sort          asc.
   *
   * @param {Object}  options - Must contain keys 'barcode', 'postal_code' and 'country_code'.
   *
   * @returns {Object}
   */
  async trackShipment(options) {
    options = {
      ...options,
      ...{
        extra_info: 'delivery_moment;returnable',
        sort: 'asc',
      },
    };

    const url = this.buildURL(this.urls.track_trace, options);
    const response = await axios.get(url);

    return response.data.data.tracktraces[0];
  },

  /**
   * @returns {Promise}
   */
  async getReturnData() {
    const url = this.getUrlWithHash(this.urls.return_shipment);
    const response = await axios.get(url);
    return response.data.data.return_shipments[0];
  },

  /**
   * Get return reason settings belonging to the current page's shop.
   *
   * @returns {Promise<string>}
   */
  async getReturnReasonSettings() {
    const url = this.getUrlWithHash(this.urls.return_reason_settings, {
      hash: router.currentRoute.params.hash,
      subdomain,
    });

    const response = await axios.get(url);

    return response.data.data.return_reason_settings[0];
  },

  /**
   * @param {Object} options - Data object.
   *
   * @returns {Promise}
   */
  emailReturnLabel(options) {
    const url = this.buildURL(this.urls.return_shipment__email_label);

    return axios.post(url, {
      data: {
        return_shipments: [options],
      },
    });
  },

  /**
   * Get valid ERS country codes.
   *
   * @returns {Promise<Object>}
   */
  async getErsCountryCodes() {
    const response = await this.cached.get(this.urls.ers_country_codes);

    return response.data.data.countries[0];
  },

  /**
   * @returns {Promise<Object>}
   */
  async getPaymentStatus() {
    const url = this.getUrlWithHash(this.urls.billing__payment_status, { subdomain });
    const response = await axios.get(url);

    return response.data.data.payment_status[0];
  },

  /**
   * Get return reasons belonging to a shipment.
   *
   * @returns {Promise<Array>}
   */
  async getReturnReasons() {
    const url = this.getUrlWithHash(this.urls.return_shipment__return_reasons);
    const response = await axios.get(url);

    return response.data.data.return_reasons;
  },

  /**
   * Get return reasons belonging to a shipment.
   *
   * @returns {string | null}
   */
  async getReturnComment() {
    const url = this.getUrlWithHash(this.urls.return_shipment__return_comment);
    const response = await axios.get(url);

    return response.data.data.return_comments?.[0]?.text;
  },

  /**
   * Post a related return shipment. Can only be used from /track-trace.
   *
   * @returns {Promise<Object>}
   */
  async postRelatedReturn() {
    const response = await axios.post(this.urls.post_return_shipments);

    return response.data.data.return_shipments[0];
  },

  /**
   * @param {string} text
   * @returns {Promise}
   */
  async postReturnComment(text) {
    const response = await axios.post(this.getUrlWithHash(this.urls.return_shipment__return_comment), {
      data: {
        return_comments: [{ text }],
      },
    });

    return response.data.data.return_comments?.[0]?.text;
  },

  /**
   * @returns {Promise}
   */
  deleteReturnComment() {
    return axios.delete(this.getUrlWithHash(this.urls.return_shipment__return_comment));
  },

  /**
   * @returns {Promise<Object>}
   */
  async getSocialShippingLink() {
    const url = this.getUrlWithUuid(this.urls.social_shipping_links);
    const response = await axios.get(url);

    return response.data.data.social_shipping_links[0];
  },

  /**
   * @param {Object} data
   * @returns {Promise<Object>}
   */
  async putSocialShippingLink(data) {
    const response = await axios.put(this.getUrlWithUuid(this.urls.social_shipping_links), {
      data: {
        social_shipping_links: [
          data,
        ],
      },
    });

    return response.data.data.social_shipping_links[0];
  },

  /**
   * @param {Object} params
   * @returns {Promise}
   */
  async checkPostalCode(params) {
    const result = await this.cached.get(this.buildURL(this.urls.postal_code_check, {
      postal_code: params.postalCode.toUpperCase().replace(/\s/, ''),
      cc: params.country || config.defaultCC,
    }));

    return Boolean(result.data.data.postal_code[0].is_valid);
  },

  /**
   * @param {Object} params
   * @returns {Promise}
   */
  async checkPhoneNumber(params) {
    const result = await this.cached.get(this.buildURL(this.urls.phone_number_check, {
      phone_number: params.phoneNumber.replace(/\s/, ''),
      cc: params.country || config.defaultCC,
    }));

    return Boolean(result.data.data.phone_number[0].is_valid);
  },

  /**
   * @param {Object} options
   * @returns {Promise<Object|null>}
   */
  async findAddress(options) {
    try {
      const result = await this.cached.get(this.buildURL(this.urls.locations, options));
      return result?.data?.data?.locations[0] || null;
    } catch (e) {
      return null;
    }
  },

  /**
   * Returns url with query parameters from url and arguments.
   *
   * @param {string} url - URL to add arguments to.
   * @param {Object} args - URL query arguments.
   * @returns {string}
   */
  buildURL(url, args = null) {
    if (!args) {
      return url;
    }

    const params = new URLSearchParams();
    let paramString = '';

    // Value can be undefined and null. Do not set those keys in the params.
    const addValueToParams = (key, value) => {
      if (!value) {
        return;
      }

      params.append(key, value.toString());
    };

    // In some cases we want to pass an Array.
    // Then we will loop through the values and set these with and &.
    Object.entries(args).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((value) => addValueToParams(key, value));
        return;
      }

      addValueToParams(key, value);
    });

    paramString = `?${params.toString()}`;

    return `${url}${paramString}`;
  },

  /**
   * @param {string} url
   * @param {Object} args
   * @param {string} hash
   * @returns {string}
   */
  getUrlWithHash(url, args = null, hash = router.currentRoute.params.hash) {
    return this.getUrlWithVariable(url, args, 'hash', hash);
  },

  /**
   * @param {string} url
   * @param {Object} args
   * @param {string} uuid
   * @returns {string}
   */
  getUrlWithUuid(url, args = null, uuid = router.currentRoute.params.uuid) {
    return this.getUrlWithVariable(url, args, 'uuid', uuid);
  },

  /**
   * Build an url replacing the given variable name by a value.
   *
   * @param {string} url
   * @param {Object} args
   * @param {string} variableName
   * @param {string} variable
   * @returns {string}
   */
  getUrlWithVariable(url, args = null, variableName, variable) {
    const apiUrl = url.replace(`{${variableName}}`, variable);
    return this.buildURL(apiUrl, args);
  },
};

export default MyParcelApi;
