import { i18n } from '@/services/i18n';

/**
 * @param {Route} route
 */
export function setPageTitle(route) {
  const { title } = route.meta;

  document.title = i18n.te(title) ? i18n.t(title) : title;
}
