import { BButton, BDropdown, BDropdownItem, BFormInput, BSpinner } from 'bootstrap-vue';
import DelayedTransitionGroup from '@/components/DelayedTransitionGroup';
import Loader from '@/components/Loader';
import SmoothReflow from '@/components/SmoothReflow';
import TransitionExpand from '@/components/TransitionExpand';
import Vue from 'vue';

// todo: MY-32205 remove all globally imported components

/*
 * Bootstrap Vue components
 */
Vue.component('BButton', BButton);
Vue.component('BDropdown', BDropdown);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BFormInput', BFormInput);
Vue.component('BSpinner', BSpinner);

/*
 * Our own global components
 */
Vue.component('DelayedTransitionGroup', DelayedTransitionGroup);
Vue.component('Loader', Loader);
Vue.component('TransitionExpand', TransitionExpand);
Vue.component('SmoothReflow', SmoothReflow);
