import { formatTrackingInformation } from './formatTrackingInformation';
import { i18n } from '@/services/i18n';

export const formatFakeTrackingInformation = (response) => {
  const tracking = formatTrackingInformation(response);

  const carrier = tracking.carrier.name;
  const { code } = tracking.status;
  tracking.status.description = i18n.t(`statuses.${carrier}.${code}`);

  tracking.history.forEach((item) => {
    item.description = i18n.t(`statuses.${carrier}.${item.code}`);
  });
  return tracking;
};
