import config from '@/helpers/config';

/**
 * Matches IETF language tags and not just "en-GB".
 *
 * @type {RegExp}
 *
 * @see test/unit/getLocaleCode.spec.js
 */
const IETF_CODE_REGEX = /^[a-z]{2,}[_-](?:[A-z]+[-_])?(?:[A-Z]{2}|\d{3})$/i;

/**
 * Convert language strings to locale codes. Falls back to default.
 *
 * @param {String} language - Language string.
 *
 * @returns {String|undefined}
 */
export function getLocaleCode(language) {
  const isLcid = language.length === 2;
  const isLocale = language.match(IETF_CODE_REGEX);

  if (!isLcid && !isLocale) {
    // eslint-disable-next-line no-console
    console.error(`Invalid language given. "${language}"`);
    return language;
  }

  language = language.toLowerCase();

  if (isLcid) {
    return config.availableLanguages.find((item) => item.startsWith(language));
  }

  language = language.replace('-', '_');

  return config.availableLanguages.find((item) => {
    const languageMatches = item.toLowerCase() === language.toLowerCase();
    const beginningMatches = item.startsWith(language.substring(0, 2));

    return languageMatches || beginningMatches;
  });
}
