import { generateLoadingMutations } from '@/store/helpers/generateLoadingMutations';
import { generateLoadingProperties } from '@/store/helpers/generateLoadingProperties';

/**
 * @param {Object} data
 * @param {string[]} loadingProperties
 *
 * @returns {import('vuex').StoreOptions}
 */
export function createStoreModule(data, loadingProperties) {
  data.state = {
    ...data.state || {},
    ...generateLoadingProperties(loadingProperties),
  };

  data.mutations = {
    ...data.mutations || {},
    ...generateLoadingMutations(loadingProperties),
  };

  return {
    namespaced: true,
    ...data,
  };
}
