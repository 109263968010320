import config from '@/helpers/config';
import { getCarrier } from '@/helpers/getCarrier';

export const formatTrackingInformation = (response) => {
  const { carrier, code, time, description, ...trackingInformation } = response;

  trackingInformation.carrier = getCarrier(carrier || config.defaultCarrier);
  trackingInformation.hasSubStatuses = trackingInformation.history.some((el) => el.main === null);
  trackingInformation.history = trackingInformation.history.map((item, index) => ({
    ...item,
    key: `${index}_${item.code}`,
  }));

  trackingInformation.status = {
    ...response.status,
    ...{
      code,
      description,
      time,
    },
  };

  return trackingInformation;
};
