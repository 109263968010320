import { black, white } from 'tailwindcss/colors';
import { isDark } from '@/services/branding/isDark';

/**
 * @param {string} backgroundColor
 * @returns {boolean|string}
 */
export function getForegroundColor(backgroundColor) {
  return isDark(backgroundColor)
    ? white
    : black;
}
