import { getRouteWithLangParameter } from '@/router/route-guard/beforeEachRoute/getRouteWithLangParameter';
import { setAxiosHeaders } from '@/router/route-guard/beforeEachRoute/setAxiosHeaders';
import { setLanguageOnRouteChange } from '@/router/route-guard/beforeEachRoute/setLanguageOnRouteChange';
import { setPageTitle } from '@/router/route-guard/beforeEachRoute/setPageTitle';

/**
 * Function being ran before entering any route.
 *
 * @param {Route} to - Router destination.
 * @param {Route} from - Previous route.
 * @param {Function} next - Redirect function.
 *
 * @returns {Promise<void>}
 */
export const beforeEachRoute = async(to, from, next) => {
  await setLanguageOnRouteChange(to);
  setPageTitle(to);
  setAxiosHeaders(to);

  const redirectRoute = getRouteWithLangParameter(to, from);

  if (redirectRoute) {
    next(redirectRoute);
  } else {
    next();
  }
};
