import MyParcelApi from '@/services/MyParcelApi';
import { REQUIRES_BRANDING } from '@/router/route-guard/middleware/middleware';
import { STORE_BRANDING } from '@/store/mutation-types';
import { checkMiddleware } from '@/router/route-guard/middleware/checkMiddleware';
import { getDemoBranding } from '@/data/demo/branding/demoBranding';
import { subdomain } from '@/helpers/platform';

/**
 * Fetch branding data. Returns if branding and the requested fields language are already present.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} context.state - Vuex state.
 *
 * @returns {Promise<Object>}
 */
export const fetchBranding = async({ commit, state }) => {
  if (state.branding) {
    return state.branding;
  }

  commit('START_LOADING_BRANDING');
  let branding;

  if (subdomain === 'demo' || !checkMiddleware(REQUIRES_BRANDING)) {
    branding = getDemoBranding();
  } else {
    branding = await MyParcelApi.getBranding();
  }

  commit(STORE_BRANDING, branding);
  commit('STOP_LOADING_BRANDING');

  return branding;
};
