/* eslint-disable no-magic-numbers */
import chromaJs from 'chroma-js';

/**
 * Copied from color.isDark from package "color" to not need the dependency.
 *
 * @param {string} color
 *
 * @returns {boolean}
 *
 * @see http://24ways.org/2010/calculating-color-contrast
 */
export function isDark(color) {
  const rgb = chromaJs(color).rgb();
  const yiq = ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000;

  return yiq < 128;
}
