import config from '@/helpers/config';
import { defaultFieldTranslations } from '@/data/demo/fields/translations';

/**
 * Use the given object to create a fields array with all available languages filled in.
 *
 * @param {Object} defaultField - Fallback data that will be used for each language.
 *
 * @returns {Object[]}
 */
export const getDemoTranslatedFields = (defaultField) => {
  return config.availableLanguages.map((lang) => ({
    ...defaultField,
    ...defaultFieldTranslations.find(({ language }) => language === lang),
    // Fallback language property in case above function finds nothing
    language: lang,
  }));
};
