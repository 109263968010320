export const demoReturnReasonSettings = {
  enabled: true,
  mandatory: false,
  return_reasons: [
    'ordered_wrong_product',
    'product_not_as_expected',
    'quality_not_as_expected',
    'received_wrong_product',
    'received_wrong_size',
  ],
};
