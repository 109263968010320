import { getLoadingPropertyName } from '@/store/helpers/getLoadingPropertyName';

/**
 * Creates an object with basic loading state properties by given names.
 *
 * @example
 *  generateLoadingProperties(['socialShippingLink']);
 *  // { loadingSocialShippingLink: false }
 *
 * @param {string[]} properties
 *
 * @returns {Object<string, boolean>}
 */
export function generateLoadingProperties(properties) {
  return properties.reduce((acc, val) => ({
    ...acc,
    [getLoadingPropertyName(val)]: false,
  }), {});
}
