<template>
  <div class="ml-auto">
    <BDropdown
      variant="light"
      right>
      <template #button-content>
        <Flag :language="currentLanguage" />
        <span v-text="currentLanguage.name" />
      </template>

      <BDropdownItem
        v-for="language in availableLanguages"
        :key="language.locale"
        href="#"
        @click.prevent="changeLanguage(language.locale)">
        <Flag :language="language" />
        <span v-text="language.name" />
      </BDropdownItem>
    </BDropdown>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import Flag from '@/components/Flag';
import { getUrlWithoutSubdomain } from '@/router/getUrlWithoutSubdomain';

const languageMap = {
  nl: 'Nederlands',
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  es: 'Español',
  it: 'Italiano',
};

export default {
  name: 'LanguageSwitcher',
  components: {
    Flag,
  },

  data() {
    return {
      dropdownVisible: false,
    };
  },

  computed: {
    /**
     * @returns {{name: string, country: string, locale: string}}
     */
    currentLanguage() {
      return this.languages.find((item) => item.locale === this.$i18n.locale);
    },

    /**
     * @returns {{name: string, country: string, locale: string}[]}
     */
    languages() {
      return this.$config.availableLanguages.map((language) => this.mapLanguage(language));
    },

    /**
     * @returns {{name: string, country: string, locale: string}[]}
     */
    availableLanguages() {
      return this.languages.filter(({ locale }) => locale !== this.currentLanguage.locale);
    },
  },

  methods: {
    /**
     * Map a locale to a language object.
     *
     * @param {string} locale - The locale to map.
     *
     * @returns {{name: string, country: string, locale: string}}
     */
    mapLanguage(locale) {
      const country = locale.substr(3, 5).toLowerCase();
      const name = languageMap[locale.substr(0, 2)];

      return {
        name,
        country,
        locale,
      };
    },

    /**
     * Update the router with a language parameter to trigger the actual language change.
     *
     * @param {string} locale - New locale.
     */
    changeLanguage(locale) {
      const domain = getUrlWithoutSubdomain(window.location.hostname);
      Cookies.set('language', locale, { domain });

      this.$router.push({ query: { ...this.$router.currentRoute.query, ...{ lang: locale } } });
    },
  },
};
</script>
