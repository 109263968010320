/**
 * @returns {Object}
 */
export const getDemoBranding = () => ({
  use_consumer_portal: true,
  enable_track_trace: true,
  enable_returns: true,
  allow_creating_related_returns: true,
  banner: 'skin/images/favicon/ms-icon-310x310.png',
  logo: 'skin/images/logo.svg',
});
