// Storing data
export const STORE_BRANDING = 'STORE_BRANDING';
export const STORE_FIELDS = 'STORE_FIELDS';
export const STORE_TRACKING_INFORMATION = 'STORE_TRACKING_INFORMATION';

// Types of loading mutations that will be added in mutations.js
export const LOADING_TYPES = [
  'BRANDING',
  'FIELDS',
  'TRACKING_INFORMATION',
];
