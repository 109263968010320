import { i18n, loadedLanguages } from '@/services/i18n/index';

/**
 * @param {string} lang
 *
 * @returns {Promise<Object|void>}
 */
export async function loadLanguage(lang) {
  if (!loadedLanguages.includes(lang)) {
    const messages = await import(`@/translations/${lang}.json`);
    loadedLanguages.push(lang);

    if (i18n) {
      i18n.setLocaleMessage(lang, messages.default);
    } else {
      return { [lang]: messages.default };
    }
  }
}
