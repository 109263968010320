import { ApiError } from '@/services/ApiError';
import { STORE_TRACKING_INFORMATION } from '@/store/mutation-types';
import fakeData from '@/services/faker';
import { formatFakeTrackingInformation } from '@/store/helpers/formatFakeTrackingInformation';
import { isDemo } from '@/services/functions/isDemo';
import { platform } from '@/helpers/platform';
import { redirect } from '@/router/redirect';
import { redirectToCorrectUrl } from '@/router/route-guard/redirectToCorrectUrl';
import { store } from '@/store';

/**
 * @param {Route} to - Router destination.
 * @param {Route} from - Previous route.
 * @param {Function} next - Redirect function.
 *
 * @returns {Promise}
 */
export const beforeEnterTrackTrace = async(to, from, next) => {
  // Continue with default data if on demo page. (Does not apply to demo subdomain)
  if (isDemo(to)) {
    store.commit(STORE_TRACKING_INFORMATION, formatFakeTrackingInformation(fakeData[platform]));

    return next();
  }

  if (!to.params.barcode) {
    redirect('No tracking information found');
  }

  try {
    const response = await store.dispatch('FETCH_TRACKING_INFORMATION', to.params);
    redirectToCorrectUrl(response, next);
  } catch (error) {
    // Barcode is not found, redirect to 404 page
    redirect(new ApiError(error));
  }
};
