import { getLocaleByAlpha2 } from 'country-locale-map';
import { loadAndSetLanguage } from '@/services/i18n/loadAndSetLanguage';

/**
 * Add the locale code from the cc query parameter if this is a correct locale code
 * or the country parameter to the languages array to try to get a matching language.
 *
 * @param {Route} route
 */
export async function setLanguageOnRouteChange(route) {
  const langRegex = new RegExp(/[a-z]{2}_[A-Z]{2}/);
  const locales = [
    langRegex.test(route.query.lang) ? route.query.lang : undefined,
    route.query.cc,
    route.params.cc,
  ];

  locales
    .filter(Boolean)
    .map((countryCode) => getLocaleByAlpha2(countryCode.toUpperCase()));

  await loadAndSetLanguage(locales);
}
