import MyParcelApi from '@/services/MyParcelApi';
import { REQUIRES_BRANDING } from '@/router/route-guard/middleware/middleware';
import { STORE_FIELDS } from '@/store/mutation-types';
import { checkMiddleware } from '@/router/route-guard/middleware/checkMiddleware';
import { demoFields } from '@/data/demo/fields/demoFields';
import { i18n } from '@/services/i18n';
import { subdomain } from '@/helpers/platform';

/**
 * Fetch fields for current language.
 *
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {Object} context.state - Vuex state.
 * @param {String} lang - Language.
 *
 * @returns {Promise}
 */
export const fetchFields = async({ state, commit }, lang = i18n.locale) => {
  const existingFields = state.fields.find(({ language }) => language === lang);

  if (existingFields) {
    return existingFields;
  }

  commit('START_LOADING_FIELDS');
  let response;

  if (subdomain === 'demo' || !checkMiddleware(REQUIRES_BRANDING)) {
    response = demoFields.find(({ language }) => language === lang);
  } else {
    response = await MyParcelApi.getFields(lang);
  }

  commit(STORE_FIELDS, { ...response, language: lang });
  commit('STOP_LOADING_FIELDS');

  return response;
};
