import { applyMiddleware } from '@/router/route-guard/middleware/applyMiddleware';
import { redirectWithoutSubdomain } from '@/router/redirectWithoutSubdomain';
import { store } from '@/store';

/**
 * Function being run after entering but before resolving any route.
 *
 * @param {Object} to - Router destination.
 * @param {Object} from - Previous route.
 * @param {Function} next - Redirect function.
 *
 * @returns {Promise<void>}
 */
export const beforeResolveRoute = async(to, from, next) => {
  try {
    await store.dispatch('FETCH_BRANDING');

    // Allow this promise to resolve anytime. There should be loaders in components which depend on this data.
    store.dispatch('FETCH_FIELDS');

    return applyMiddleware(to, from, next);
  } catch (error) {
    await redirectWithoutSubdomain(error, to);
  }
};
