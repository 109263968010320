/* eslint-disable max-lines-per-function */
import {
  NO_BRANDING,
  REQUIRES_BRANDING,
  REQUIRES_RETURNS,
} from '@/router/route-guard/middleware/middleware';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import { STORE_MODULE_SOCIAL_SHIPPING } from '@/store/modules/social-shipping/constants';
import { createRouteWithDemo } from '@/router/createRouteWithDemo';
import { registerStoreModule } from '@/store/modules/registerStoreModule';
import { trackTraceRoute } from '@/router/trackTraceRoute';

/**
 * Create the routes.
 *
 * @returns {Route[]}
 */
export function createRoutes() {
  return [
    {
      path: '*',
      redirect: '/404',
    },
    {
      path: '/',
      alias: '/backoffice',
      name: 'Home',
      meta: {
        title: 'home.title',
        middleware: [REQUIRES_BRANDING],
      },
      component: () => import('@/views/Home'),
    },
    {
      ...trackTraceRoute,
      name: 'TrackTraceNoPostalCode',
      path: '/track-trace/:barcode/:cc([A-z]{2})',
      alias: '/backoffice/track-trace/:barcode/:cc([A-z]{2})',
    },
    ...createRouteWithDemo(trackTraceRoute),
    ...createRouteWithDemo({
      path: '/returns/:hash',
      alias: '/backoffice/returns/:hash',
      name: 'Returns',
      meta: {
        title: 'returns.title',
        middleware: [REQUIRES_RETURNS],
      },
      beforeEnter(to, from, next) {
        registerStoreModule(STORE_MODULE_RETURNS);
        next();
      },
      component: () => import('@/views/Returns'),
    }),
    ...createRouteWithDemo({
      path: '/ship/:uuid',
      alias: '/backoffice/ship/:uuid',
      name: 'SocialShipping',
      meta: {
        title: 'social_shipping.title',
      },
      beforeEnter(to, from, next) {
        registerStoreModule(STORE_MODULE_SOCIAL_SHIPPING);
        next();
      },
      component: () => import('@/views/SocialShipping'),
    }),
    ...['canceled', 'completed'].map((type) => ({
      path: `/payment_${type}/:hash`,
      name: `Payment ${type}`,
      meta: {
        title: `payment_${type}.title`,
        middleware: [NO_BRANDING],
      },
      props: {
        type,
      },
      component: () => import('@/views/PaymentStatus'),
    })),
    {
      path: '/404',
      name: 'Error404',
      meta: {
        title: 'error404.title',
      },
      component: () => import('@/views/Error404'),
    },
  ];
}
