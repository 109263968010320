/* eslint-disable camelcase */
import { FLESPAKKET, MYPARCEL, SENDMYPARCEL } from '@/helpers/platform/data';
import { bpost_demo } from '@/data/demo/trackingInformation/bpost_demo';
import { fles_demo } from '@/data/demo/trackingInformation/fles_demo';
import { postnl_demo } from '@/data/demo/trackingInformation/postnl_demo';

/**
 * Demo data for each locale.
 */
export default {
  [FLESPAKKET]: fles_demo,
  [MYPARCEL]: postnl_demo,
  [SENDMYPARCEL]: bpost_demo,
};
