export const defaultFieldTranslations = [
  {
    language: 'nl_NL',
    banner_action_title: 'Jouw promotie hier!',
    returns: {
      introduction: 'Voldoen de producten niet aan uw verwachtingen? Terugsturen kan!<br>Met het retourlabel kunt u uw bestelling retourneren.',
    },
  },
  {
    language: 'en_GB',
    banner_action_title: 'Your promotion here!',
    returns: {
      introduction: 'Did the received items fail to meet your expectations? You can return them!<br>You can return your order using the return label.',
    },
  },
  {
    language: 'fr_FR',
    returns: {
      introduction: 'Les produits ne correspondent pas à vos attentes ? Vous pouvez alors les renvoyer !<br>Grâce à l’étiquette de renvoi, vous pouvez renvoyer votre commande.',
    },
  },
  {
    language: 'fr_BE',
    returns: {
      introduction: 'Les produits ne correspondent pas à vos attentes ? Vous pouvez alors les renvoyer !<br>Grâce à l’étiquette de renvoi, vous pouvez renvoyer votre commande.',
    },
  },
  {
    language: 'es_ES',
    returns: {
      introduction: '¿Los productos no cumplen sus expectativas? ¡Puede devolverlos!<br>Con la etiqueta de devolución puede enviar su pedido de vuelta.',
    },
  },
  {
    language: 'de_DE',
    returns: {
      introduction: 'Entsprechen die Produkte nicht Ihren Erwartungen? Sie können sie zurücksenden!<br>Mit dem Retourenlabel können Sie Ihre Bestellung zurücksenden.',
    },
  },
  {
    language: 'it_IT',
    returns: {
      introduction: 'I prodotti non soddisfano le sue aspettative? Si possono rendere!<br>Utilizzando l\'etichetta di reso può restituire il suo ordine.',
    },
  },
];
