import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import { STORE_MODULE_SOCIAL_SHIPPING } from '@/store/modules/social-shipping/constants';
import { createReturnsStoreModule } from '@/store/modules/returns/createReturnsStoreModule';
import { createSocialShippingStoreModule } from '@/store/modules/social-shipping/createSocialShippingStoreModule';
import { store } from '@/store';

const storeModuleMap = {
  [STORE_MODULE_RETURNS]: createReturnsStoreModule,
  [STORE_MODULE_SOCIAL_SHIPPING]: createSocialShippingStoreModule,
};

/**
 * Register a store module by name if it doesn't exist yet.
 *
 * @param {string} name
 */
export function registerStoreModule(name) {
  if (store.hasModule(name)) {
    return;
  }

  const storeModule = storeModuleMap[name]();
  store.registerModule(name, storeModule);
}
