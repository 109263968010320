export class ApiError {
  code;
  config;
  data;
  headers;
  message;
  request;
  status;

  hasErrors = false;

  /**
   * @param {Error|Object|String} error
   */
  constructor(error) {
    if (typeof error === 'string') {
      this.message = error;
    } else {
      Object.keys(error).forEach((key) => {
        this[key] = error[key];
      });

      this.hasErrors = this.data && this.data.errors && this.data.errors.length > 0;
    }
  }

  getMessage() {
    if (this.hasErrors) {
      return this.data.errors[0].message;
    }

    return this.data ? this.data.message : this.message;
  }

  getCode() {
    return this.hasErrors ? this.data.errors[0].code : null;
  }

  getStatus() {
    return this.status;
  }
}
