import MyParcelApi from '@/services/MyParcelApi';
import { STORE_RETURN_COMMENT } from '@/store/modules/returns/constants';

/**
 * @param {Object} context - Vuex context.
 * @param {Function} context.commit - Vuex commit function.
 * @param {string | null} payload
 */
export async function postReturnComment({ commit }, payload) {
  if (!payload) {
    await MyParcelApi.deleteReturnComment();
  }

  const returnComment = await MyParcelApi.postReturnComment(payload);

  commit(STORE_RETURN_COMMENT, returnComment);
}
