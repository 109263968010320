import Vue from 'vue';
import customParseFormat from 'dayjs/esm/plugin/customParseFormat';
import dayjs from 'dayjs/esm';
import { getLcid } from '@/services/i18n/getLcid';

Vue.prototype.$dayjs = dayjs;

// Fix for "Invalid date" in Safari (and other browsers).
dayjs.extend(customParseFormat);

export const dateFormats = {
  withoutMs: 'YYYY-MM-DD HH:mm:ss',
  withMs: 'YYYY-MM-DD HH:mm:ss.SSS',
};

Vue.prototype.$dateFormats = dateFormats;

const loadedDayJsLanguages = [];

/**
 * Import language file from dayjs and set its locale to given language.
 *
 * @param {string} lang - Locale.
 */
export async function setDayJsLanguage(lang) {
  const lcid = getLcid(lang);

  if (!loadedDayJsLanguages.includes(lcid)) {
    await import(`dayjs/esm/locale/${lcid}.js`);

    loadedDayJsLanguages.push(lcid);
  }

  dayjs.locale(lcid);
}

export { dayjs };
