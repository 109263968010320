import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import { isDev } from '@/helpers/environment';
import mutations from './mutations';
import state from '@/store/state';

Vue.use(Vuex);

export let store;

/**
 * Creates a store by given options. The store is created in this verbose way to allow us to substitute the entire
 * store in tests.
 *
 * @param {Object} newStore
 *
 * @returns {Object}
 */
export function createStore(newStore = null) {
  store = newStore ?? new Vuex.Store({
    state,
    mutations,
    actions,
    getters,

    strict: isDev,
  });

  return store;
}

/**
 * For use in tests.
 */
export function unsetStore() {
  store = null;
}
