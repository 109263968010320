import { getUrlWithoutSubdomain } from '@/router/getUrlWithoutSubdomain';
import { redirect } from '@/router/redirect';

/**
 * Redirect to given page if on prod, if not just show the error.
 *
 * @param {Object} error - Error/data to log.
 * @param {Object} to - Router destination.
 * @param {String} location - URL to trim subdomain off and redirect to.
 */
export const redirectWithoutSubdomain = (error, to, location = window.location.href) => {
  const url = new URL(getUrlWithoutSubdomain(location));

  url.pathname = to.path;

  // Redirect to 404 if there already is no subdomain.
  if (url.href === window.location.href) {
    redirect(error);
    return;
  }

  redirect(error, url.href);
};
