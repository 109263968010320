import { startCase } from 'lodash-es/string';

/**
 * Takes the results from the address finder and ports them to the recipient properties.
 *
 * @param {Object} foundAddress
 * @returns {Object}
 */
export function createRecipientFromAddressFinderData(foundAddress) {
  // Fix the casing. City is returned in uppercase so this at least improves most city names.
  const city = startCase((foundAddress.city ?? '').toLowerCase());

  return {
    street: foundAddress.streetName ?? null,
    number: foundAddress.houseNumber ? `${foundAddress.houseNumber}` : null,
    number_suffix: foundAddress.houseNumberSuffix ? `${foundAddress.houseNumberSuffix}` : null,
    postal_code: foundAddress.postalCode ?? null,
    city,
  };
}
