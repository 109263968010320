import { defaultField } from '@/data/demo/fields/demoFields';
import { getDemoTranslatedFields } from '@/data/demo/fields/getDemoTranslatedFields';

export const defaultSendMyParcelFields = getDemoTranslatedFields({
  ...defaultField,
  banner_action_url: 'https://www.sendmyparcel.be/',
  company: {
    cc: 'BE',
    name: 'MyParcel',
    street: 'Adriaan Brouwerstraat 16',
    postal_code: '2000',
    city: 'Antwerpen',
    phone: '03 808 18 20',
  },
  social_widget: {
    type: 'twitter_timeline',
    identifier: 'myparcelbelgium',
  },
  social_links: {
    facebook: 'MyParcelBE',
    twitter: 'myparcelbelgium',
    linkedin: 'myparcel-belgium',
  },
});
