const VARIANTS = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark',
  // Additional text variants
  'white',
  'muted',
];

/**
 * Adds a bootstrap compatible variant prop.
 *
 * @type {Object}
 */
export const hasVariantProp = {
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator(value) {
        return VARIANTS.includes(value);
      },
    },
  },
};
