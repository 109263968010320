import axios from '@/services/axios';

/**
 * Set the x-referer header to the path the route will resolve to.
 *
 * We can't use window.location.href here because it won't match the new route
 *  at this point, and updating the axios header after fully resolving the route
 *  will be too late for requests being done in beforeEach and beforeResolve
 *  router hooks.
 *
 * @param {Route} route
 */
export function setAxiosHeaders(route) {
  axios.defaults.headers['X-Referer'] = window.location.origin + route.path;
}
