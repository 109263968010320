import { subdomain as currentSubdomain } from '@/helpers/platform';

/**
 * @param {String} url
 * @param {String} subdomain - Defaults to current subdomain from platform variables.
 * @returns {String}
 */
export function getUrlWithoutSubdomain(url, subdomain = currentSubdomain) {
  if (!url.includes(subdomain)) {
    return url;
  }

  return url.replace(new RegExp(`${subdomain}\\.`), '');
}
