import './redirect';
import Router from 'vue-router';
import Vue from 'vue';
import { beforeEachRoute } from '@/router/route-guard/beforeEachRoute';
import { beforeResolveRoute } from '@/router/route-guard/beforeResolveRoute';
import { createRoutes } from '@/router/createRoutes';

Vue.use(Router);

export let router;

/**
 * @returns {VueRouter}
 */
export function createRouter() {
  router = new Router({
    mode: 'history',
    routes: createRoutes(),
  });

  /**
   * Runs BEFORE the per-route route guards.
   */
  router.beforeEach(beforeEachRoute);

  // Between these two functions the 'beforeEach' function of the route being navigated to will be executed.

  /**
   * Runs AFTER the per-route route guards are done.
   */
  router.beforeResolve(beforeResolveRoute);

  return router;
}
