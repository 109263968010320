/**
 * Adds a demo version of the route before the given route. In the demo version of the route any variables in the route
 * path and alias are replaced with "demo" and isDemo: true is added to meta.
 *
 * @example
 *  // /track-trace/:barcode/:postal_code/:cc? -> /track-trace/demo
 *
 * @param {Router.Route} route
 *
 * @returns {Router.Route[]}
 */
export function createRouteWithDemo(route) {
  const paths = [route.path];
  paths.push(...Array.isArray(route.alias) ? route.alias : [route.alias]);

  const [demoPath, ...demoAlias] = paths.map((path) => {
    return path?.replace(/:.+/, 'demo');
  });

  const demoRoute = {
    ...route,
    name: `${route.name}Demo`,
    alias: demoAlias,
    path: demoPath,
    meta: {
      ...route.meta ?? {},
      isDemo: true,
    },
  };

  return [
    demoRoute,
    route,
  ];
}
