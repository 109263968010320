/* eslint-disable no-magic-numbers */
import { BPOST, CHEAP_CARGO, DHL, DPD, INSTABOX, POSTNL } from '@/helpers/carriers';
import { getUrl, platform } from '@/helpers/platform';
import envVariables from '@/services/env-variables';

const env = envVariables.values;

const defaultConfig = {
  // URLS
  apiURL: env.API_URL ?? getUrl('api'),
  assetsURL: getUrl('assets'),
  backofficeURL: getUrl('backoffice'),

  // API keys
  googleMapsAPIkey: 'AIzaSyDntAvv_X6ze-KrqTgEBmMzBtR6vCkFjcE',

  // Language settings
  availableLanguages: [
    'nl_NL',
    'en_GB',
    'fr_FR',
    'de_DE',
    'es_ES',
    'it_IT',
  ],
  defaultCC: 'NL',
  defaultLanguage: 'nl_NL',
  fallbackLanguage: 'en_GB',

  // Shipment options
  visibleOptions: [
    'age_check',
    'cooled_delivery',
    'only_recipient',
    'return',
    'signature',
  ],

  // Carrier options
  defaultCarrier: POSTNL,

  // Statuses
  STATUS_ACCEPTED: 3,
  STATUS_SORTING: 4,
  STATUS_DISTRIBUTION: 5,
  STATUS_DELIVERED: 6,

  // Favicons
  iconSizes: [16, 32, 96, 196],

  // Name of the cookie to store cookie consent
  consentCookieName: 'cookie_consent',
};

// Configs for each platform
const config = {
  flespakket: {
    ...defaultConfig,
    assetsURL: env.ASSETS_FLESPAKKET_NL ?? defaultConfig.assetsURL,
    backofficeURL: env.BACKOFFICE_FLESPAKKET_NL ?? defaultConfig.backofficeURL,
    url404: env.PAGE_NOT_FOUND_FLESPAKKET_NL ?? 'https://flespakket.nl',
    assets: {
      privacyStatement: {
        nl_NL: `${defaultConfig.assetsURL}/documentatie/voorwaarden/flespakket-privacy-statement.pdf`,
        en_GB: `${defaultConfig.assetsURL}/documentatie/voorwaarden/flespakket-privacy-statement-en.pdf`,
      },
      cookieStatement: {
        nl_NL: `${defaultConfig.assetsURL}/documentatie/voorwaarden/flespakket-cookie-statement.pdf`,
        en_GB: `${defaultConfig.assetsURL}/documentatie/voorwaarden/flespakket-cookie-statement-en.pdf`,
      },
    },
  },

  myparcel: {
    ...defaultConfig,
    assetsURL: env.ASSETS_MYPARCEL_NL ?? defaultConfig.assetsURL,
    backofficeURL: env.BACKOFFICE_MYPARCEL_NL ?? defaultConfig.backofficeURL,
    url404: env.PAGE_NOT_FOUND_MYPARCEL_NL ?? 'https://myparcel.nl/404',
    assets: {
      privacyStatement: {
        nl_NL: `${defaultConfig.assetsURL}/documentatie/voorwaarden/myparcel-privacy-statement.pdf`,
        en_GB: `${defaultConfig.assetsURL}/documentatie/voorwaarden/myparcel-privacy-statement-en.pdf`,
      },
      cookieStatement: {
        nl_NL: `${defaultConfig.assetsURL}/documentatie/voorwaarden/myparcel-cookie-statement.pdf`,
        en_GB: `${defaultConfig.assetsURL}/documentatie/voorwaarden/myparcel-cookie-statement-en.pdf`,
      },
    },
  },

  sendmyparcel: {
    ...defaultConfig,
    assetsURL: env.ASSETS_SENDMYPARCEL_BE ?? defaultConfig.assetsURL,
    backofficeURL: env.BACKOFFICE_SENDMYPARCEL_BE ?? defaultConfig.backofficeURL,
    availableLanguages: [
      'nl_BE',
      'fr_BE',
      'en_GB',
      'de_DE',
      'es_ES',
      'it_IT',
    ],
    defaultCC: 'BE',
    defaultCarrier: BPOST,
    defaultLanguage: 'nl_BE',
    url404: env.PAGE_NOT_FOUND_SENDMYPARCEL_BE ?? 'https://sendmyparcel.be',
    assets: {
      privacyStatement: {
        nl_BE: `${defaultConfig.assetsURL}/documentatie/voorwaarden/privacyverklaring.pdf`,
        fr_BE: `${defaultConfig.assetsURL}/documentatie/voorwaarden/declaration-de-confidentialite.pdf`,
        en_GB: `${defaultConfig.assetsURL}/documentatie/voorwaarden/privacy-statement.pdf`,
      },
      cookieStatement: {
        nl_BE: `${defaultConfig.assetsURL}/documentatie/voorwaarden/cookieverklaring.pdf`,
        fr_BE: `${defaultConfig.assetsURL}/documentatie/voorwaarden/tout-sur-les-cookies.pdf`,
        en_GB: `${defaultConfig.assetsURL}/documentatie/voorwaarden/cookie-statement.pdf`,
      },
    },
  },
};

/**
 * Setting the assets fields with the dynamic urls.
 * This needs to set after the config because of the assetsUrl.
 */
const configPlatform = config[platform];

configPlatform.carrierLogoPath = `${configPlatform.assetsURL}/skin/general-images/carrier-logos`;
configPlatform.flagPath = `${configPlatform.assetsURL}/skin/general-images/flags/`;
configPlatform.carrierLogo = {
  [BPOST]: `${configPlatform.carrierLogoPath}/bpost.png`,
  [CHEAP_CARGO]: `${configPlatform.carrierLogoPath}/svg/ups.svg`,
  [DPD]: `${configPlatform.carrierLogoPath}/dpd.png`,
  [POSTNL]: `${configPlatform.carrierLogoPath}/postnl.png`,
  [INSTABOX]: `${configPlatform.carrierLogoPath}/instabox.png`,
  [DHL]: `${configPlatform.carrierLogoPath}/svg/dhl.svg`,
};

export default config[platform];
